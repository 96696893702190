<template>
  <b-modal :active.sync="isModalActive" scroll="clip" :class="{ 'max-width-custom': registerOverDrawingMode }" :width="modalWidth" :can-cancel="canCancel">
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>

    <div v-if="!isLoading"
      class="max-h-screen bg-white border rounded shadow overflow-y-none border-blue-light p-4 relative"
      :style="'height: '+ (registerOverDrawingMode ? '90vh' : 'initial' ) + ';'">
      <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>

      <header class="w-full text-xl font-semibold uppercase text-blue-light inline-block">
        <div class="flex justify-between">
          <div>
            {{ $t("progress_record") }}
          </div>
          <div class="text-xs font-semibold" v-if="workOrder">
            <span class="text-blue">{{ $t('purchase_order') }}:</span> <br>
            <span>{{ workOrder.code }}</span>
          </div>
          <div class="text-xs font-semibold" v-if="workOrder">
            <span class="text-blue">{{ $t('position') }}:</span> <br>
            <span>{{ workOrder.position }}</span>
          </div>
        </div>
      </header>

      <div class="flex flex-row space-x-3">

        <div v-if="registerOverDrawingMode"
          class="bg-gray-50 w-1/5 panel overflow-y-auto"
          style="height: 80vh;">
          <documents-preview
            :entity-id="entityId"
            :entity-type="entityType"
            :selectedDocument.sync="selectedDocument"
          />
        </div>

        <div v-if="registerOverDrawingMode"
          class="panel bg-gray-50 w-2/3 overflow-y-auto"
          style="height: 80vh;">
          <document-editor ref="pdf_editor"
            :entity-id="entityId"
            :entity-type="entityType"
            :enableMeasurement="true"
            :enableBtnSave="false"
            @drawing-line="handleDrawingLine"
            @data-processing-finished="handleProcessingDataFinished"
          />
        </div>

        <div class="panel bg-gray-50"
          :class="{ 'w-1/3' : registerOverDrawingMode }"
          :style="'height: ' + (registerOverDrawingMode ? '80vh' : 'initial' ) + ';'">

          <assignment-contribute ref="assignment_contribute"
            :entity-id="entityId"
            :entity-type="entityType"
            :currentStartDate="currentStartDate"
            :currentEndDate="currentEndDate"
            :assignmentId="assignmentId"
            @processing-data="handleProcessingData"
            @save-pdf="handleSaveDocument"
            @assigment-load-data-order="_HandleLoadDataOrder"
            :registerOverDrawingMode.sync="registerOverDrawingMode"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import {mapGetters} from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import AssignmentContribute from './assignment-contribute.vue'
  import DocumentEditor from './document-editor.vue'
  import DocumentsPreview from './documents-preview.vue'

  export default {
    name: "assignment-contribute-drawing-modal",

    components: {
      ScaleLoader,
      AssignmentContribute,
      DocumentEditor,
      DocumentsPreview
    },

    props: {
      entityId: { type: Number, required: true },
      entityType: { type: String, required: true },
      currentStartDate: { type: String, required: true },
      currentEndDate: { type: String, required: true },
    },

    data: () => ({
      isModalActive: false,
      isOpen: false,
      isPdf: true,
      isLoading: true,
      assignmentId: 0,
      unitCapture: null, //variable que almacena la medida captura en el visor de pdftron

      processingData: false,

      canCancel: ['escape', 'x', 'outside'],
      selectedDocument: null,

      registerOverDrawingMode: false, // indica si la medición se realizará sobre un plano
      modalWidth: '35%',

      workOrder: null
    }),

    mounted() {

    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    watch: {
      registerOverDrawingMode(newValue, oldValue) {
        this.$nextTick(() => {
          this.modalWidth = (newValue ? '90%' : '30%')
        });
      },

      selectedDocument(newValue, oldValue) {
        if( newValue != null )
          this.showDocument(newValue)
      }
    },

    methods: {

      // handleDrawingLine(annotations) {
      handleDrawingLine(measure) {
        // let measure = annotations[0].uka
        // if( ! measure )
        //   measure = annotations[0].Aka
        // this.$refs.assignment_contribute.setUnits( measure.split(' ')[0].replace('"', '').replace(',', '') )
        this.$refs.assignment_contribute.setUnits( measure )
      },
      handleProcessingData(event) {
        this.processingData = event
        if( ! event ) {
          this.canCancel = ['escape', 'x', 'outside']
          this.hide()
        }
        else
          this.canCancel = !event
      },

      handleProcessingDataFinished(event) {
        this.processingData = false
        this.canCancel = ['escape', 'x', 'outside']

        this.hide()
      },

      showDocument(document) {
        this.$refs.pdf_editor.show(document)
      },

      show(assignment_id) {
        this.isLoading = true
        this.isModalActive = true;
        this.assignmentId = assignment_id;
        this.canCancel = ['escape', 'x', 'outside']
        this.selectedDocument = null
        this.processingData = false
        this.registerOverDrawingMode = false

        this.$nextTick(() => {
          setTimeout(() => {
            // Simulate asynchronous task completion
            this.isLoading = false
          }, 1000);
        });
      },

      hide() {
        this.assignmentId = 0;
        this.selectedDocument = null
        this.processingData = false
        this.isModalActive = false;
        this.registerOverDrawingMode = false
      },

      handleSaveDocument(data){
        if( this.registerOverDrawingMode )
          this.$refs.pdf_editor.clickButtonSavePdfTron(data)
      },

      _HandleLoadDataOrder(data) {
        this.workOrder = {
          code: data.code,
          position: data.position
        }
      }
    }
  };
</script>

<style scoped>
::v-deep.max-width-custom .modal-content {
  max-width: 90% !important;
}
</style>
