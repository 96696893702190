<template>
  <div v-if="activity"> 

    <div class="panel">
          <div class="w-full flex flex-wrap">
            <div class="panel">
              <form @submit.prevent="addPrelation" class="w-full">
                <div class="w-full flex">
                  <div class="w-1/3 pr-3">
                    <label for="type" class="w-full">{{$t('link_with_activity')}}</label>
                    <b-select ref="input"
                      v-model="item.type"
                      :placeholder="$t('link_with_activity')"
                      name="type"
                      class="w-full"
                      required
                      expanded
                    >
                      <option v-for="option in types" :value="option" :key="option">
                        {{ $t(option) }}
                      </option>
                    </b-select>
                  </div>

                  <div class="w-1/3 pr-3">
                    <label for="link_type" class="w-full">{{$t('link_type')}}</label>
                    <b-select ref="input"
                      v-model="item.link_type"
                      :placeholder="$t('link_type')"
                      name="link_type"
                      class="w-full"
                      required
                      expanded
                    >
                      <option v-for="option in linkTypes" :value="option" :key="option">
                        {{ option }}
                      </option>
                    </b-select>
                  </div>

                  <div class="w-1/3">
                    <label for="activity_id" class="w-full">{{ $t('select_activity') }}</label>
                    <b-select
                        v-model="item.activity_id"
                        :placeholder="$t('select_activity')"
                        icon="clipboard-list"
                        icon-pack="fas"
                        expanded
                        @input="loadSubactivities()"
                    >
                      <option v-for="(option, index) in activities" :value="option['id']" :key="'activity_'+index">
                        {{ option['name'] }}
                      </option>

                    </b-select>
                  </div>
                </div>

                <div class="w-full flex mt-2">
                  <div class="w-1/3 pr-3">
                    <label for="activity_id" class="w-full">{{ $t('select_subactivity') }}</label>
                    <b-select
                        v-model="item.subactivity_id"
                        :placeholder="$t('select_subactivity')"
                        icon="clipboard-list"
                        icon-pack="fas"
                        expanded
                    >
                      <option v-for="(option, index) in subactivities" :value="option['id']" :key="'subactivity_'+index">
                        {{ option['name'] }}
                      </option>

                    </b-select>
                  </div>

                  <div class="w-1/3 pr-3">
                    <label for="days" class="w-full">{{$t('days')}}</label>
                    <v-input
                      v-model="item.days"
                      name="days"
                      :placeholder="$t('days')"
                      class="w-full"
                      type="number"
                      required
                    />
                  </div>

                  <div class="w-1/3 relative">
                    <v-button
                      native-type="submit"
                      class="w-full absolute bottom-0"
                      :disabled="!item.activity_id || !item.link_type || item.days == '' || isNaN(item.days) || validateActivity()"
                    >{{ $t('add') }}
                    </v-button>
                  </div>
                </div>

              </form>
            </div>
          </div>
      
      <div class="flex mt-5">
        <div class="w-1/2 p-2">
            <table class="w-full my-2">  
                <tr class="bg-blue-lightest">
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('predecessors')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('type')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('days')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark"></th>
                </tr>
                <tr v-for="(pre, index) in predecessors">
                    <td class="border border-blue-lighter p-2">{{pre.name}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{pre.link_type}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{pre.days}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">
                      <i class="fas fa-trash text-red cursor-pointer" @click="removePrelation('predecessors', index)"></i>
                    </td>
                </tr>
            </table>
        </div>
        <div class="w-1/2 p-2">
            <table class="w-full my-2">  
                <tr class="bg-blue-lightest">
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('successors')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('type')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('days')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark"></th>
                </tr>
                <tr v-for="(suc, index) in successors">
                    <td class="border border-blue-lighter p-2">{{suc.name}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{suc.link_type}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{suc.days}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">
                      <i class="fas fa-trash text-red cursor-pointer" @click="removePrelation('successors', index)"></i>
                    </td>
                </tr>
            </table>
        </div>
      </div>

      <form @submit.prevent="sendForm"
        class="w-full flex sm:flex-row items-center justify-end mb-4 mt-4">
        <v-button type="submit" icon="thumbs-up" icon-pack="far" class="mr-2">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{$t('close')}}</v-button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  middleware: 'auth',
  title: 'linked_activities',
  subtitle: 'linked_activities',
  menuOption: '2-2',

  components: {
  },

  data: () => ({
    activityId: null,
    activity: null,
    predecessors: [],
    successors: [],
    types: ['predecessors', 'successors'],
    linkTypes: ['FC', 'FF', 'CC', 'CF'],
    activities: [],
    subactivities: [],
    item: {
      type: 'predecessors',
      link_type: 'FC',
      activity_id: null,
      subactivity_id: null,
      days: "0"
    }
  }),

  computed: {
    ...mapGetters({
      project: 'app/project'
    }),
  },

  created() {
    this.activityId = this.$route.params.subactivity_id || this.$route.params.activity_id
    this.getActivityData()
    this.getActivities()
  },

  mounted() {

  },

  methods: {


    async getActivityData() {
        const { data } = await axios.get('/api/v2/activity/' + this.activityId + '?with_prelations=1')
        if (data && data.success) {
          this.activity = data.data

          this.successors = data.data.all_next_activities.map(a => {
            return {
              activity_id: a.id,
              name: a.name,
              link_type: a.pivot.link_type,
              days: a.pivot.days
            }
          })
          this.predecessors = data.data.all_pre_activities.map(a => {
            return {
              activity_id: a.id,
              name: a.name,
              link_type: a.pivot.link_type,
              days: a.pivot.days
            }
          })

        } else {
          this.$notify.error('error_loading_activity_data')
        }
    },

    async getActivities(){
      const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.project.id, per_page: 999}});
      this.activities = data.activities;
    },

    async getSubactivities(activityId){
      const { data } = await axios.get("/api/v2/activity/" + activityId + "/subactivities");
      return data.subactivities;
    },

    async loadSubactivities() {
      this.item.subactivity_id = null;
      this.subactivities = await this.getSubactivities(this.item.activity_id);
    },

    addPrelation() {
      let findActivity = null;
      if (this.item.subactivity_id) {
        findActivity = this.subactivities.find(a => a.id == this.item.subactivity_id);
      } else {
        findActivity = this.activities.find(a => a.id == this.item.activity_id);
      }
      this[this.item.type].push({
        activity_id: findActivity.id,
        name: findActivity.name,
        link_type: this.item.link_type,
        days: this.item.days
      })

      this.item.activity_id = null
      this.item.subactivity_id = null
      this.item.days = "0"
    },

    validateActivity() {
      let activityId = this.item.subactivity_id || this.item.activity_id
      return this.successors.some(suc => suc.activity_id == activityId) || this.predecessors.some(pre => pre.activity_id == activityId)
    },

    removePrelation(type, index) {
      this[type].splice(index, 1);
    },

    async sendForm() {
      let body = {
        predecessors: {},
        successors: {}
      }
      this.predecessors.map(pre => {
        body.predecessors[pre.activity_id] = {
          link_type: pre.link_type,
          days: pre.days
        }
      })
      this.successors.map(suc => {
        body.successors[suc.activity_id] = {
          link_type: suc.link_type,
          days: suc.days
        }
      })

      const { data } = await axios.post('/api/v2/activity/' + this.activityId + '/sync-prelations', body)
      if (data && data.success) {
        this.$notify.success("success");
      }
    },

  }
};
</script>
