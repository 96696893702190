<template>
  <div>
    <!-- Caja de nuevo comentario -->
    <entity-comment-add
      ref="newComment"
      :entity-id="entityId"
      :entity-type="entityType"
      :documents-list="documentsList"
      @comment-saved="reload"
      @editor-hided="isEditing=false"
    />

    <!-- Cuerpo del comentario -->
    <div v-if="!isEditing"
         :class="(isChild) ? 'bg-blue-lightest p-1 my-2' : 'panel mb-4 comment-lists'"
         :key="comment.id">
      <!-- Tags -->
      <div class="w-full mb-4" v-if="comment.tags.length > 0">
        <visualize-tags
          :tags="comment.tags"
        />
      </div>
      <!-- Top -->
      <div class="flex items-center mb-4">
        <!-- Usuario -->
        <div class="flex items-center">
          <div class="w-10 h-10 mr-2 overflow-hidden border rounded-full border-grey">
            <img :src="comment.user.avatar" :alt="comment.user.fullname">
          </div>
          <div>
            <div class="font-medium text-blue">
              {{ comment.user.fullname }}
            </div>
            <div class="text-sm text-grey" v-if="!comment.updated_at">
              {{ moment(comment.created_at).format('DD/MM/YYYY H:mm:ss') }}
            </div>
            <div class="text-sm text-grey" v-else>
              {{ moment(comment.updated_at).format('DD/MM/YYYY H:mm:ss') }}
              <span v-if="comment.updated_at && (comment.updated_at > comment.created_at)"
              class="p-1 mr-3 text-xs font-bold uppercase rounded-full bg-yellow-lighter text-grey-dark">{{ $t('edited') }}</span>
            </div>

          </div>
        </div>
      </div>
      <!-- Contenido -->
      <div v-html="comment.comment" class="mb-4 text-sm"></div>

      <!-- Botonera -->
      <div class="flex items-center justify-end float-right ml-2">
        <!-- Botón responder -->
        <v-button
          icon="comments"
          icon-pack="far"
          size="small"
          color="blue"
          @click="answerComment(comment.id)"
          v-if="canAdd"
        >
          {{ $t('answer') }}
        </v-button>

        <v-button
          icon="edit"
          icon-pack="far"
          size="small"
          class="ml-1"
          @click="editComment(comment.id)"
          v-if="canUserEdit(comment.user.user_id)"
        />

        <v-button
          icon="trash-alt"
          icon-pack="far"
          size="small"
          color="red"
          class="ml-1"
          @click="deleteComment(comment.id)"
          v-if="canUserEdit(comment.user.user_id)"
        />

      </div>

      <!-- documentos -->
      <b-collapse v-if="comment.documents.length" class="pl-4" :open="false">
        <div slot="trigger" slot-scope="props"
             class="flex items-center text-sm font-semibold"
        >
          {{ comment.documents.length + ' ' + $t('documents') }}
          <b-icon pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'" class="ml-2 text-blue"/>
        </div>
        <div class="mt-1">
          <document-item-list
            v-for="(doc, index) in comment.documents"
            :key="doc.document_id"
            :document="doc"
            :parent-id="entityId"
            :can-delete="canUserEdit(doc.user.user_id)"
            :entity-type="entityType"
            :bg-color="index % 2 ? 'bg-white' : 'bg-grey-lighter'"
            @reload-documents-list="reload"
          />
        </div>
      </b-collapse>

      <!-- Respuestas -->
      <b-collapse v-if="hasChildren()" :open.sync="comment.isAnswersOpen" class="pl-4">
        <div slot="trigger" slot-scope="props"
             class="flex items-center text-sm font-semibold"
        >
          {{ countChildren() + ' ' + $t('answers') }}
          <b-icon pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'" class="ml-2 text-blue"/>
        </div>
        <div v-for="answer in comment.children"
             class="p-1 my-2 bg-blue-lightest"
        >
          <entity-comment
            :comment="answer"
            :documents-list="documentsList"
            :entityId="entityId"
            :isSubactivity="isSubactivity"
            :canAdd="false"
            :entityType="entityType"
            :is-child="true"
            @reload="reload"
          />
        </div>
      </b-collapse>
      <div v-else-if="!isChild" class="text-sm italic font-semibold text-grey">
        {{ $t('no_answers') }}
      </div>
    </div>

  </div>
</template>

<script>
  import Vue from "vue"
  import axios from 'axios'
  import EntityCommentAdd from '·/components/entity-comment-add'
  import store from "~/store";
  import moment from 'moment'
  import VisualizeTags from "·/components/visualize-tags";
  import entityDocumentThumbnail from "./entity-document-thumbnail";
  import DocumentItemList from "./document-item-list";

  Vue.prototype.moment = moment;

  export default {
    name: 'entity-comment',

    components: {
      DocumentItemList,
      EntityCommentAdd,
      VisualizeTags,
      entityDocumentThumbnail
    },

    props: {
      comment: {type: Object, default: null},
      documentsList: {type: Array, required: true},
      entityId: {type: Number, required: true},
      isSubactivity: {type: Boolean, default: false},
      canAdd: {type: Boolean, default: false},
      entityType: {type: String, required: true},
      isChild: {type: Boolean, default: false},
    },

    data: () => ({
      isEditing: false,
      comments: [],
      isLoading: true,
      canEdit: false,
      commentId: 0,
      title: '',
      tags: [],
    }),

    methods: {
      addComment() {
        this.$refs.newComment.new()
      },

      editComment(commentId) {
        this.isEditing = true;
        this.$refs.newComment.edit(commentId)
      },

      deleteComment(commentId) {
        this.$dialog.confirm({
          title: this.$t('delete_comment'),
          message: this.$t('delete_comment_confirm_text'),
          confirmText: this.$t('delete_comment'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let self = this;
            let url = '/api/v2/' + self.entityType + '/comment/' + commentId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              self.$notify.success('success_deleting_comment')
              this.reload();
            } else {
              self.$notify.error(data.error)
            }
          }
        })
      },

      countChildren() {
        if (this.comment.children)
          return this.comment.children.length;

        return 0;
      },

      hasChildren() {
        return this.countChildren() != 0;
      },

      canUserEdit(commentAuthor) {
        let authUserId = store.getters["auth/user"] ? store.getters["auth/user"].id : null
        return commentAuthor == authUserId
      },

      answerComment(parentComment) {
        this.$refs.newComment.answer(parentComment)
      },

      reload() {
        this.$emit("reload");
      }
    },

  };
</script>

<style>
  .comment-lists {
    overflow-wrap: break-word;
  }

  .comment-lists ul {
    list-style: disc !important;
    padding-left: 1.5em;
  }

  .comment-lists ol {
    padding-left: 1.5em;
  }
</style>
