<template>
  <section>
    <div class="panel">
      <div class="w-full mb-4">
        <div class="flex w-full">
          <p class="content">
            <b>{{ $t('order_field') }}</b>
          </p>
        </div>
        <div class="flex w-full">
          <b-radio v-model="orderField" native-value="name">
            {{ $t('name') }}
          </b-radio>
          <b-radio v-model="orderField" native-value="planning_code">
            {{ $t('planning_code') }}
          </b-radio>
          <b-radio v-model="orderField" native-value="current_start_date">
            {{ $t('current_start_date') }}
          </b-radio>
          <b-radio v-model="orderField" native-value="current_end_date">
            {{ $t('current_end_date') }}
          </b-radio>
        </div>
      </div>
      <div class="w-full mb-4">
        <div class="flex w-full">
          <p class="content">
            <b>{{ $t('order_dir') }}</b>
          </p>
        </div>
        <div class="flex w-full">
          <b-radio v-model="orderDir" native-value="asc">
            {{ $t('ascending') }}
          </b-radio>
          <b-radio v-model="orderDir" native-value="desc">
            {{ $t('descending') }}
          </b-radio>
        </div>
      </div>
      <div class="flex w-full">
        <v-button type="button" color="green" icon="file-export" @click="exportActivities">{{ $t('export') }}</v-button>
      </div>
    </div>
    <div class="panel">
      <div class="flex w-full mb-2">
        <span class="font-bold"><b-icon pack="fas" icon="filter"></b-icon>{{ $t('filters') }}</span>
        <button class="button btn ml-2" style="background-color: #B8C2CB; color: white" @click="cleanFilters()">
          {{ $t('clean_filters') }}
        </button>
      </div>
      <div class="flex items-center mb-4">
        <div class="w-1/5 mr-4">
          <b-field :label="$t('scheduled_start_date')">
            <b-datepicker v-model="filters.scheduled_start_date" position="is-top-right"
              :placeholder="$t('scheduled_start_date')" :month-names="$t('months_names')" :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false">
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-1/5 mr-4">
          <b-field :label="$t('scheduled_end_date')">
            <b-datepicker v-model="filters.scheduled_end_date" position="is-top-right"
              :placeholder="$t('scheduled_end_date')" :month-names="$t('months_names')" :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false">
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-1/5 mr-4">
          <b-field :label="$t('current_start_date')">
            <b-datepicker v-model="filters.current_start_date" position="is-top-right"
              :placeholder="$t('current_start_date')" :month-names="$t('months_names')" :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false">
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-1/5 mr-4">
          <b-field :label="$t('current_end_date')">
            <b-datepicker v-model="filters.current_end_date" position="is-top-right"
              :placeholder="$t('current_end_date')" :month-names="$t('months_names')" :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false">
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-1/5 mr-4">
          <b-field :label="$t('search_by_status')">
            <b-select v-model="filters.status" :placeholder="$t('activity_status')" icon="search" expanded>
              <option value="0">{{ $t('activity_status_assigned') }}</option>
              <option value="1">{{ $t('activity_status_running') }}</option>
              <option value="2">{{ $t('activity_status_paused') }}</option>
              <option value="3">{{ $t('activity_status_issue') }}</option>
              <option value="4">{{ $t('activity_status_done') }}</option>
              <option value="5">{{ $t('activity_status_reopened') }}</option>
              <option value="6">{{ $t('activity_status_closed') }}</option>
            </b-select>
          </b-field>
        </div>
      </div>


      <div class="w-full mb-4">
        <filter-tags @filterList="filterByTags($event)" :tags-array="projectTags" ref="filterTags" />
      </div>

    </div>
  </section>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import moment from 'moment'
import FilterTags from "·/components/filter-tags";

Vue.use(moment)

export default {
  name: 'export-activities',

  props: {
    project_id: { type: Number, default: 0 },
  },

  components: { FilterTags },

  data: () => ({
    projectTags: [],
    filters: {
      scheduled_start_date: null,
      scheduled_end_date: null,
      current_start_date: null,
      current_end_date: null,
      status: null,
      tags: []
    },
    orderField: 'name',
    orderDir: 'asc'
  }),

  created() {
    this.getProjectTags();
  },

  methods: {
    exportActivities() {

      let params = {
        projectId: this.project_id,
        orderDir: this.orderDir,
        orderField: this.orderField,
      }

      if (this.filters.scheduled_start_date !== null) {
        params.scheduled_start_date = moment(this.filters.scheduled_start_date).format('YYYY-MM-DD')
      }

      if (this.filters.scheduled_end_date !== null) {
        params.scheduled_end_date = moment(this.filters.scheduled_end_date).format('YYYY-MM-DD')
      }

      if (this.filters.current_start_date !== null) {
        params.current_start_date = moment(this.filters.current_start_date).format('YYYY-MM-DD')
      }

      if (this.filters.current_end_date !== null) {
        params.current_end_date = moment(this.filters.current_end_date).format('YYYY-MM-DD')
      }

      if (this.filters.status !== null) {
        params.status = this.filters.status
      }

      if (this.filters.tags.length > 0) {
        params.tags = this.filters.tags
      }

      var self = this

      axios({
        method: 'get',
        url: '/api/v2/export/activities',
        responseType: 'blob',
        params: params
      })
        .then(function (response) {
          self.$notify.success('success_export');
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = self.$t('activities') + '.xlsx';
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);
        })
        .catch(error => {
          self.$notify.error('error_export')
          console.log(error)
        })
    },

    // Carga las tags recibidas y aplica el filtro
    filterByTags(tags) {
      this.filters.tags = tags;
    },

    async getProjectTags() {
      const { data } = await axios.get('/api/v2/project/' + this.project_id + '/tags')
      if (data && data.success) {
        this.projectTags = data.tags.map(function (tag) {
          return { text: tag.name };
        });
      }
    },

    cleanFilters() {
      this.filters = {
        scheduled_start_date: null,
        scheduled_end_date: null,
        current_start_date: null,
        current_end_date: null,
        status: null,
        tags: [],
      }
      this.$refs.filterTags.resetTags();
    },
  },
};

</script>
