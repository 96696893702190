<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-toggle" v-model="currentActiveTab" expanded size="is-medium">
          <b-tab-item :disabled="sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> {{ $t('step') + ' 1' }} </span>
            </template>

            <list-activities ref="list_activities"
              :projectId="projectId"
              :clearSelectItems="activities.length == 0"
              @selected-items="onSelectedItems"
              @selected-subitems="(items) => { subactivities = items }"
            />
          </b-tab-item>

          <!-- Acción a realizar sobre la lista previamente seleccionada -->
          <b-tab-item :disabled="(currentActiveTab != 1 && !selectedAction) || sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="bolt"></b-icon>
              <span>{{ $t('step') + ' 2' }}</span>
            </template>
            <div>

              <list-users ref="list_users"
                :projectId="projectId"
                :action="selectedAction"
                :collaborator="selectedAction && selectedAction.name === 'assign_collaborators'"
                @selected-linked-entities="(items) => { linkedEntities = items }"
                v-if="showListUsers()"
              />

              <form-data :projectId="projectId"
                :action="selectedAction"
                @selected-linked-entities="(items) => { linkedEntities = items }"
                :optionTags="project.tags"
                v-if="selectedAction && (selectedAction.name === 'change_data' || selectedAction.name === 'link_tags' || selectedAction.name === 'assign_group' )"
              />

              <documents-list :projectId="projectId"
                :action="selectedAction"
                @selected-linked-entities="(items) => { linkedEntities = items }"
                v-if="selectedAction && selectedAction.name === 'link_documents'"
              />

              <validations-list :projectId="projectId"
                :action="selectedAction"
                @selected-linked-entities="(items) => { linkedEntities = items }"
                v-if="selectedAction && selectedAction.name === 'link_validations'"
              />

              <bulk-assign-group v-if="selectedAction && selectedAction.name === 'assign_group_'"
                :projectId="projectId"
                :entity-type="'project'"
                :action="selectedAction"
                @selected-linked-entities="(items) => { linkedEntities = items }"
              />

            </div>
          </b-tab-item>

          <!-- Muestra resumen de la acción y opcion para procesar la acción o cancelar -->
          <b-tab-item :disabled="currentActiveTab != 2 && !linkedEntities.length">
            <template slot="header">
              <b-icon pack="fas" icon="hourglass-end"></b-icon>
              <span>{{ $t('step') + ' 3' }}</span>
            </template>

            <WizardBulkComplete :activities="activities"
              :subactivities="subactivities"
              :action="selectedAction"
              :linkedEntities="linkedEntities"
              :projectId="projectId"
              v-if="selectedAction && activities.length && linkedEntities.length"
              ref="wizard_complete"
            />
          </b-tab-item>

        </b-tabs>

        <div class="sticky bottom-0 ml-4 mr-4">
          <div class="panel mt-0 mb-0">
            <BulkActionsButtons :show="activities.length > 0 && currentActiveTab == 0"
              :clearSelect="!selectedAction"
              @select-action="onSelectAction"
            />
            <WizardBulkButtons :step="currentActiveTab"
              :complete="currentActiveTab === 2"
              :processingData="sendProcessData"
              @wizard-previus="prevTab"
              @wizard-next="nexTab"
            />
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
  import axios from "axios";
  import ListActivities from "../../components/bulk-actions/activities-list.vue";
  import ListUsers from "../../components/bulk-actions/users-list.vue";
  import FormData from "../../components/bulk-actions/bulk-change-data.vue";
  import DocumentsList from "../../components/bulk-actions/documents-list.vue";
  import ValidationsList from "../../components/bulk-actions/validations-list.vue";
  import WizardBulkButtons from '·/components/bulk-actions/wizard-bulk-buttons';
  import WizardBulkComplete from '·/components/bulk-actions/wizard-bulk-complete';
  import BulkActionsButtons from '·/components/bulk-actions/bulk-actions-buttons';
  import BulkAssignGroup from '·/components/bulk-actions/bulk-assign-group';

  export default {
    middleware: "auth",
    title: "bulk_actions_page_title",
    menuOption: "0-2",

    components: {
      ListActivities,
      ListUsers,
      FormData,
      DocumentsList,
      ValidationsList,
      WizardBulkButtons,
      BulkActionsButtons,
      WizardBulkComplete,
      BulkAssignGroup
    },

    computed: {
      projectId() {
        // console.log(this.$route.params.)
        return parseInt(this.$route.params.project_id);
      }
    },

    data: () => ({
      currentActiveTab: 0,
      activities: [],
      subactivities: [],
      linkedEntities: [],
      selectedAction: null,
      sendProcessData: false,

      project: null,
    }),

    watch: {
      currentActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    mounted() {
    },

    created() {
      this.getProjectData()
    },

    methods: {

      async getProjectData() {
        let params = {};
        const {data} = await axios.get("/api/v2/project/" + this.projectId, {params: params});
        if (data) {
          this.project = data
          // this.$refs.model_tags.initTags(data.tags);
          this.$options.subtitle = ["project_actions_page_subtitle", [this.project.name.toUpperCase()]];

        } else {
          this.$notify.error("error_loading_project_data");
        }

        this.isLoading = false;
      },

      prevTab () {
        if (this.currentActiveTab === 0 ) return
        const newTabIndex = this.currentActiveTab - 1
        const oldTabIndex = this.currentActiveTab
        // emit('change', newTabIndex, oldTabIndex)
        // await props.beforeChange()
        this.changeTab(newTabIndex)
      },

      nexTab() {
        if (this.currentActiveTab === 2) {
          this.completeWizard()
          return
        }

        if ( this.currentActiveTab == 0 && !this.validStep1() ) return
        if ( this.currentActiveTab == 1 && !this.validStep2() ) return
        // if ( this.currentActiveTab == 2 && !this.validStep2() ) return

        const newTabIndex = this.currentActiveTab + 1
        const oldTabIndex = this.currentActiveTab
        // emit('change', newTabIndex, oldTabIndex)
        // await props.beforeChange()
        this.changeTab(newTabIndex)
      },

      async completeWizard() {
        this.sendProcessData = true
        await this.$refs.wizard_complete.sendForm();
        this.sendProcessData = false

        // limpiamos datos seleccionados
        this.linkedEntities = []
        this.selectedAction = null
        this.activities = []
        this.subactivities = []

        this.$refs.list_activities.applyFilters(1)
        this.changeTab(0)
      },

      async changeTab (newTabIndex) {
        this.currentActiveTab = newTabIndex

        this.setActiveIndex()
        await this.$nextTick()
        // await props.beforeMount()
      },

      setActiveIndex()  {
        // TODO: No Hace nada ver para que puede ser usado
        // const currentTabs: any = tabs.value.entries()
        // for (const [index, tab] of currentTabs) {
        //     if (index === maxTabIndex.value + 1) {
        //         return
        //     }
        //     tab.checked = index < currentTabIndex.value
        //     tab.active = index === currentTabIndex.value
        // }
        // emit('updated:tabs', tabs, currentTabIndex)
      },

      validStep1() {
        if( this.selectedAction && this.activities.length > 0)
          return true

        // Mostramos el error
        this.$toast.open({
          message: 'Debe seleccionar al menos una actividad y una acción',
          type: "is-danger",
          position: "is-top-right",
        });

        return false
      },

      validStep2() {
        if( this.linkedEntities.length > 0)
          return true

        // Mostramos el error
        this.$toast.open({
          'message': this.$t('Debe seleccionar al menos un usuario'),
          'position': 'is-top-right',
          'type': 'is-danger'
        })

        return false
      },

      showListUsers() {
        return this.selectedAction && (this.selectedAction.name === 'assign_manager' || this.selectedAction.name === 'assign_participants' || this.selectedAction.name === 'assign_collaborators' || this.selectedAction.name === 'assign_tracking_user')
      },

      async onSelectAction(action) {
        this.selectedAction = action;
        this.linkedEntities = [];

        this.$nextTick(() => {
          if( this.selectedAction.name === 'assign_manager' || this.selectedAction.name === 'assign_participants' || this.selectedAction.name === 'assign_collaborators' || this.selectedAction.name === 'assign_tracking_user' )
            this.$refs.list_users.reload()
        })
      },

      onSelectedItems(items) {
        this.activities = items

        if( !items.length )
          this.selectedAction = null
      },
    }
  };
</script>
