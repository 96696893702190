<template>
    <section>

        <b-table
            :data="data"
            ref="table"
            detailed
            hoverable
            detail-key="id"
            icon-pack="fas"
            class="text-sm"
            :has-detailed-visible="hasChildren">

            <template slot="empty">
                <div class="m-1">
                    <span>{{ $t('empty_activities_table_msg') }}</span>
                </div>
            </template>

            <b-table-column field="id" :visible="false" v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" :label="name" v-slot="props">
                <div class="cursor-pointer"  @click="goToActivity(props.row)">{{ props.row.name }}</div>
            </b-table-column>

            <b-table-column field="progress" :label="$t('progress')" centered v-slot="props">
                {{ props.row.progress }}%
            </b-table-column>

            <b-table-column field="type" :label="$t('type')" centered v-slot="props">
                {{ props.row.pivot.link_type }}
            </b-table-column>

            <b-table-column field="days" :label="$t('days')" centered v-slot="props">
                {{ props.row.pivot.days }}
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <pre-activity-table :data="props.row[childrenField]" :name="name" :childrenField="childrenField"/>
            </template>
        </b-table>

    </section>
</template>

<script>
export default {
  name: 'pre-activity-table',
  props: {
    data: { type: Array, required: true },
    childrenField: { type: String, required: true},
    name: { type: String, required: true}
  },

  data() {
    return {
    }
  },

  methods: {
    hasChildren(row) {
      return row[this.childrenField]?.length
    },

    goToActivity(activity) {
      if (activity.parent_id)
        this.$router.push({'name': 'subactivity.home', params: {activity_id: activity.parent_id, subactivity_id: activity.id}})
      else
        this.$router.push({'name': 'activity.home', params: {activity_id: activity.id}})
    }
  }
}
</script>

<style>
.detail-container {
    padding: 0 !important;
}
</style>