<template>
<div class="activity-documents panel">
  <div class="flex items-center justify-between w-full">
    <!-- Título -->
    <div class="text-lg font-thin uppercase text-blue-light">{{ $t('validations') }}</div>
  </div>

  <div v-if="isLoading" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>

  <div v-else>
    <div v-if="! isLoading" class="flex flex-wrap mt-4">


      <!-- Tabla de validaciones -->
      <div class="flex items-center my-4 w-full" v-if="flowsList.length">

          <table class="w-full">

            <thead class="bg-blue-lightest">
              <tr>
                <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("validation_flow") }}</th>
                <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("status") }}</th>
                <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("current_groups") }}</th>
                <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("created_at") }}</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="flow in flowsList">

                <!-- Recorremos los recursos -->
                <tr class="hover:bg-grey-lightest cursor-pointer" :key="flow.id" @click="goWithTheFlow(flow.id)">

                  <!-- Icono Detalles / Historia -->
                  <td class="justify-start w-auto h-full p-4 align-middle text-xs text-blue font-semibold">
                    {{ flow.template.name }}<br>
                    <small class="text-grey-dark">{{flow.description}}</small>
                  </td>

                  <td class="justify-start w-auto h-full p-4 align-middle">
                    <validation-status-label :status="flow.status" class="w-full" style="vertical-align: middle"/>
                  </td>

                  <td class="justify-start w-auto h-full p-4 align-middle text-xs">
                    <span v-for="group in flow.current_groups" :key="'group_'+group.id" class="w-4 h-4 p-3 mr-1 text-xs text-center text-white rounded-full btn btn-blue">
                      <b-tooltip multilined position="is-top">
                          {{ group.code }}
                          <template v-slot:content>
                            <b>{{group.name}}</b>
                            <br> 
                            ({{ flow.current_task_manager_group.code }})
                            {{ currentTask(flow.current_task) }}
                        </template>
                      </b-tooltip>
                    </span>
                  </td>
                  <td class="justify-start w-auto h-full text-xs p-4 align-middle">
                    <small>{{flow.created}}</small>
                  </td> 

                </tr>

              </template>
            </tbody>
          </table>


      </div>
      <div v-else>
        <div  class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">

              <span>{{ $t("no_validations_found") }}</span>
        </div>
      </div>


    </div>

  </div>

  <!-- tasks -->
  <div class="w-full" v-if="entityType == 'activity'">
      <hr>
      <div class="text-lg font-thin uppercase text-blue-light">{{ $t('tasks') }}</div>
      <!-- Tabla de tareas -->
      <div v-if="isLoadingTasks" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
      </div>
      <div v-else>
        <div class="flex items-center my-4" v-if="tasksList.length">

            <table class="w-full">

                <thead class="bg-blue-lightest">
                <tr>
                    <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark">Resp.</th>
                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("task") }}</th>
                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("status") }}</th>
                </tr>
                </thead>

                <tbody>
                <template v-for="row in tasksList">

                    <tr class="hover:bg-grey-lightest" :key="row.id">

                        <!-- manager group -->
                        <td class="justify-start w-auto h-full p-4 align-middle">
                            <b-tooltip :label="row.manager_group.name" position="is-right">
                            <div class="w-8 h-8 p-2 text-white text-xs rounded-full btn btn-blue">
                                {{ row.manager_group.code }}
                            </div>
                            </b-tooltip>

                        </td>

                        <!-- nombre task -->
                        <td class="justify-start w-auto h-full p-4 align-middle cursor-pointer" @click="goWithTheFlow(row.flow.id)">
                            {{ row.name }}
                            <div class="text-xs text-grey-dark font-bold">
                              {{ row.flow.template.name }}
                            </div>
                        </td>

                        <!-- status -->
                        <td class="justify-start w-auto h-full p-4 align-middle">
                            <validation-task-status-label :status="row.status" class="w-full" />
                        </td>
                    </tr>

                </template>
                </tbody>
            </table>
        </div>
        <div v-else class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">

            <span>{{ $t("no_results") }}</span>

        </div>
      </div>

  </div>

</div>
</template>

<script>
import axios from "axios";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import validationStatusLabel from '·/components/validations/validation-status-label';
import validationTaskStatusLabel from '·/components/validations/validation-task-status-label';

export default {
  name: "entity-validationflows",

  components: {
    ScaleLoader,
    validationStatusLabel,
    validationTaskStatusLabel
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
  },

  data: () => ({
    flowsList: [],
    isLoading: true,
    isLoadingTasks: true,
    orderField: 'name',
    tasksList: [],
  }),

  created() {
    // Referenciamos
    var self = this;

    this.loadFlowsList(this.entityId, this.entityType);
    if (this.entityType == 'activity') {
      this.getTasksList();
    }

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshValidations', function (e) {
      if (!e || !e.validation_flow_id || self.flowsList.some(v => v.id == e.validation_flow_id)) {
        self.isLoading = true;
        self.loadFlowsList(self.entityId, self.entityType);
      }
    });

  },

  beforeDestroy(){
  	EventBus.$off("refreshValidations")
  },


  methods: {
    async loadFlowsList(entityId, entityType) {

      this.isLoading = true
      let self = this

      let params = {
        per_page: this.perPage,
        page: this.page,
        order_field: this.orderField,
        // sin filro status, para que devuelva todas, incluso completadas
      }

      if (entityType == 'project') {
        params.p = entityId
      } else if (entityType == 'activity') {
        params.a = entityId
      }

      await axios.get("/api/v2/validations", { params: params })
        .then(function(response) {

          if (response.data.validations) {
            self.flowsList = response.data.validations;
          }

        })
        .catch(function(error) {
          self.$notify.error('error_loading_validations')
          console.log(error);
        })
        .finally(function () {
          self.isLoading = false;
        });
    },

    currentTask(task) {
      return task ? task.name : '-'
    },

    // Go to validationflow home
    goWithTheFlow(validationId) {
        this.$router.push({name: 'validation.home', params: {validation_id: validationId}})
    },

    async getTasksList() {
      let {data} = await axios.get(`/api/v2/validations/${this.entityType}/${this.entityId}/tasks`)
      if (data.success) {
        this.tasksList = data.data;
        this.isLoadingTasks = false;
      } else {
        this.$notify.error("error_retrieving_data");
      }
    }
  }
};
</script>
