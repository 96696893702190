<template>
  <div>
    <div>
      <label class="text-bold">Paso 1</label>
      <p>Seleccione las actividades y la acción a realizar</p>
    </div>
    <!-- Filtros -->
    <activity-filters :projectId="projectId"
      @filters-updated="filtersUpdated"
      ref="filtersRef"
    />

    <div class="text-sm panel">
      <template>
        <!-- Contador de resultados -->
        <div class="flex mb-2">
          <div v-if="total" class="flex flex-1 text-left items-end">
            <b>{{ $t('count_results') }}: {{ total }} {{ $t('activities') }}</b>
          </div>
          <div class="float-right-">
            <b-select v-model="perPage" @input="filtersUpdated">
              <option value="100">{{ $t('per_page', {amount: 100}) }}</option>
              <option value="150">{{ $t('per_page', {amount: 150}) }}</option>
              <option value="200">{{ $t('per_page', {amount: 200}) }}</option>
            </b-select>
          </div>
        </div>

        <b-table
          :data="list"
          :loading="isLoadingTable"

          paginated
          backend-pagination
          :per-page="perPage"
          :current-page.sync="currentPage"
          :total="total"
          @page-change="onPageChange"

          backend-sorting
          :default-sort="orderField"
          :default-sort-direction="orderDir"
          @sort="onSort"

          detailed
          detail-key="id"
          icon-pack="fas"
          :has-detailed-visible="displaySubactivitiesOrAssignments"
          :opened-detailed="[this.openedSubactivitiesId]"
          @details-open="openDetails"
          @details-close="(row, index) => this.openedSubactivitiesId = 0"

          class="text-xs border-blue-lighter"
          bordered
          narrowed
          striped

          checkable
          :checked-rows.sync="checkedRows"
          @check="handleCheck"
          @check-all="handleCheckAll"
        >

          <template slot="empty">
            <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('empty_activities_table_msg') }}</span>
            </div>
          </template>

          <template>
              <b-table-column field="group" cell-class="w-8 vertical-center" centered>
                <template v-slot:header="">
                  <i class="fas fa-layer-group"></i>
                </template>
                <template v-slot="activities">
                  <div v-if="activities.row.group"
                    class="relative inline-flex items-center justify-center cursor-pointer w-7 h-7 overflow-hidd rounded-full"
                      :style="{'background-color' : colorGroup(activities.row.group) }">
                    <b-tooltip :label="activities.row.group.name"
                      position="is-right"
                      multilined>
                      <span class="font-medium text-white">{{ nameGroup(activities.row.group) }}</span>
                      <template v-slot:content>
                        <div><b>Grupo: </b> {{ activities.row.group.name }}</div>
                      </template>
                    </b-tooltip>
                  </div>
                  <div v-else
                    class="relative inline-flex items-center justify-center w-7 h-7 bg-gray-100 rounded-full dark:bg-gray-600">
                    <b-tooltip :label="$t('without_group')"
                      position="is-right">
                      <span class="font-medium text-gray-600 dark:text-gray-300">SG</span>
                    </b-tooltip>
                  </div>
                </template>
              </b-table-column>

            <b-table-column field="user" label="" cell-class="w-12 vertical-center"
                            centered>
              <template v-slot:header="">
                <i class="fas fa-user"></i>
              </template>
              <template v-slot="activities">
                <b-tooltip v-if="activities.row.manager && activities.row.manager.user_id"
                          :label="activities.row.manager.fullname">
                  <img
                    :src="activities.row.manager.avatar"
                    class="w-6 h-6 overflow-hidden rounded-full">
                </b-tooltip>
              </template>
            </b-table-column>

            <b-table-column field="planning_code" :label="$t('code')"
                            sortable cell-class="vertical-center" v-slot="activities">
              <div>
                {{ activities.row.planning_code }}
              </div>
            </b-table-column>

            <b-table-column field="name" :label="$t('name')" sortable
                            cell-class="vertical-center" v-slot="activities">
                <div class="cursor-pointer-"
                    @click="onRowClicked(activities.row.is_subactivity, activities.row.parent_id, activities.row.id)">
                  <template v-if="activities.row.parent !== null">
                    <b-tooltip :label="activities.row.parent !== null ? activities.row.parent.name : ''" position="is-top"
                          multilined>
                    {{ activities.row.name }}
                    </b-tooltip>
                  </template>
                  <template v-else>
                    {{ activities.row.name }}
                  </template>
                </div>
            </b-table-column>

            <b-table-column field="current_start_date" :label="$t('start')"
                            cell-class="w-25 vertical-center" sortable
                            centered v-slot="activities">
              {{ activities.row.current_start_date }}
            </b-table-column>

            <b-table-column field="current_end_date" :label="$t('end')"
                            cell-class="w-25 vertical-center" sortable centered v-slot="activities">
              {{ activities.row.current_end_date }}
            </b-table-column>

            <b-table-column field="id" numeric cell-class="is-hidden" :visible="false" v-slot="activities">
              {{ activities.row.id }}
            </b-table-column>

          </template>

          <template slot="detail" slot-scope="activities">

            <!-- Subactividades -->
            <div v-if="displaySubactivities(activities.row)">

              <div>
                <h4>
                  <b>{{ activities.row.subactivities_num }} {{
                      activities.row.subactivities_num > 1 ?
                        $t('subactivities') : $t('subactivity')
                    }}</b>
                </h4>
              </div>

              <template>
                <b-table
                  ref="subactivities"
                  :data="getSubactivities(activities.row)"
                  default-sort="notifications"
                  default-sort-direction="desc"
                  bordered
                  class="text-xs border-blue-lighter bg-grey-lighter subactivities"
                  narrowed

                  :checkable="isActivitySelected(activities.row) > -1"
                  :checked-rows.sync="checkedSubRows"
                  @check="handleCheckSubactivities"

                  icon-pack="fas"
                  :row-class="(row, index) => row.access ? '' : 'disabled-subactivity'"
                  >

                  <template>
                    <b-table-column field="user" label="" cell-class="w-12 vertical-center" centered >
                      <template v-slot:header="">
                        <i class="fa fa-sm fa-user"></i>
                      </template>
                      <template v-slot="subactivities_info">
                        <b-tooltip v-if="subactivities_info.row.manager" :label="subactivities_info.row.manager.fullname">
                          <img :src="subactivities_info.row.manager.avatar"
                              class="w-6 h-6 overflow-hidden rounded-full reduce">
                        </b-tooltip>
                      </template>
                    </b-table-column>

                    <b-table-column field="planning_code" sortable cell-class="vertical-center">
                      <template v-slot:header="">
                        {{ $t('code') }}
                      </template>
                      <template v-slot="subactivities_info">
                        <div>{{ subactivities_info.row.planning_code }}</div>
                      </template>
                    </b-table-column>

                    <b-table-column field="name" cell-class="vertical-center" sortable>
                      <template v-slot:header="">
                        {{ $t('name') }}
                      </template>
                      <template v-slot="subactivities_info">
                        <div>
                          {{ subactivities_info.row.name }}
                        </div>
                      </template>
                    </b-table-column>

                    <b-table-column field="current_start_date" cell-class="w-25 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ $t('start') }}
                      </template>
                      <template v-slot="subactivities_info">
                      {{ subactivities_info.row.current_start_date }}
                      </template>
                    </b-table-column>

                    <b-table-column field="current_end_date" cell-class="w-25 vertical-center" centered sortable>
                      <template v-slot:header="">
                        {{ $t('end') }}
                      </template>
                      <template v-slot="subactivities_info">
                        {{ subactivities_info.row.current_end_date }}
                      </template>
                    </b-table-column>

                  </template>

                </b-table>
              </template>
            </div>
          </template>

        </b-table>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ActivityFilters from '·/components/activity-filters';

export default {
  name: 'activity-list',

  components: {
    ActivityFilters,
  },

  props: {
    projectId: {type: Number, required: true},
    clearSelectItems: { type: Boolean, default: false }
  },

  data: () => ({
    isLoadingTable: true,
    page: 1,
    last: 1,
    perPage: 100,
    currentPage: 1,
    total: 0,
    orderField: "planning_code",
    orderDir: "asc",
    list: [],
    filterSubactivities: false,
    showSubs: false,
    openedSubactivitiesId: 0,
    subactivities: [],
    showFilters: false,
    generatedFilters: {}, // objeto con los filtros generados por el componente 'activity-filters'

    showExcelReport: false,
    showLotesReport: false,
    childStatusList: [], // lista status de componente activity-filters
    apiProject: {}, // algunas caracteristicas del proyecto actual devueltas por api (si user es certificador)

    checkedRows: [], // contiene las actividades seleccionadas
    checkedSubRows: [], // contiene las subactividades seleccionadas

  }),

  mounted() {
    this.applyFilters(this.page);
  },

  created() {

  },

  watch: {
    clearSelectItems(newVal, oldVal) {
      if( newVal )
        this.checkedRows = []
        this.checkedSubRows = []
    }
  },

   methods: {
    async getActivitiesList(args = []) {
      this.checkedRows = []
      const {data} = await axios.get('/api/v2/activities', {params: args});
      // Last and total
      this.last = data.lastPage;
      this.total = data.total;
      this.apiProject = data.project;
      // guarda en la localstore la ultima pagina
      // this.$store.dispatch('searchFilters/setActivitiesFilters', { last: this.last });

      if (data && data.activities) {
        this.list = this.activitiesRemapping(data.activities);
      } else {
        this.$notify.error('error_loading_activities')
      }

      this.isLoadingTable = false
    },

    /**
     * Remapea la actividad para esta tabla
     */
    activitiesRemapping(activities) {
      // for (let a in activities) {

      // }
      return activities;
    },

    displaySubactivities(row) {
      return !row.isSubactivity && row.subactivities_num > 0
    },

    displaySubactivitiesOrAssignments(row) {
      return !!this.displaySubactivities(row);
    },

    filtersUpdated() {
      this.isLoadingTable = true;
      this.applyFilters(1);
    },

    onRowClicked(isSubactivity, activityId, subactivityId, access = true) {
      //TODO: al hacer click sobre el nombre del nombre de actividad no debe ralizar nada por eso se comenta
      // if (access) {
      //   if (!isSubactivity) {
      //     this.$router.push({name: 'activity.home', params: {activity_id: activityId}})
      //   } else {
      //     this.$router.push({
      //       name: 'subactivity.home',
      //       params: {activity_id: activityId, subactivity_id: subactivityId}
      //     })
      //   }
      // }
    },

    applyFilters(page) {
      this.generatedFilters = this.customizeFilters();
      this.generatedFilters.page = (page <= this.last) ? page : 1;
      // actualiza en localstore la pagina actual
      // this.$store.dispatch('searchFilters/setActivitiesFilters', { page: this.generatedFilters.page });
      this.currentPage = this.generatedFilters.page;
      this.getActivitiesList(this.generatedFilters)
    },

    onPageChange(page) {
      // guarda en la localstore la pagina que estamos
      // this.$store.dispatch('searchFilters/setActivitiesFilters', { page: page });
      this.openedSubactivitiesId = 0;
      this.page = page;
      this.fetchData()
    },

    onSort(field, order) {
      this.openedSubactivitiesId = 0;
      this.orderField = field;
      this.orderDir = order;
      this.fetchData()
    },

    openDetails(row, index) {
      if (typeof this.subactivities[row.id] === 'undefined') {
        this.isLoadingTable = true;
        let self = this;
        let url = '/api/v2/activity/' + row.id + '/subactivities';
        let params = {with_assignments: 1};
        axios.get(url, {params: params}).then(function (response) {
          if (response.data && response.data.success) {
            self.subactivities[row.id] = response.data.subactivities
          }
          self.isLoadingTable = false;
          self.openedSubactivitiesId = row.id
        })
      } else {
        self.isLoadingTable = false;
        self.openedSubactivitiesId = row.id
      }
    },

    customizeFilters() {
      let filters = this.$refs.filtersRef.generateFilters();
      filters.with_assignments = 1;
      filters.order_field = this.orderField;
      filters.order_dir = this.orderDir;
      filters.per_page = this.perPage;
      filters.light = 1;
      filters.s = 1; //con subactividades. Como se piden de todas formas al desplegar, se quitan de aqui para aligerar carga

      if (this.filterSubactivities) {
        filters.filter_subactivities = 1
      }

      return filters;
    },

    getSubactivities(row) {
      return this.subactivities[row.id]
    },

    fetchData() {
      if (this.page <= this.last) {
        this.isLoadingTable = true;
        this.applyFilters(this.page);
        this.isLoadingTable = false
      }
    },
    handleCheck(checkedList, row) {
      this.dispatchSelected(checkedList)
    },

    handleCheckAll(checkedList) {
      // this.dispatchSelected(checkedList)
    },

    dispatchSelected(checkedList) {
      this.$emit('selected-items', checkedList)
    },

    handleCheckSubactivities(checkedList, row) {
      // this.dispatchSubactivitiesSelected(checkedList)
      this.$emit('selected-subitems', checkedList)
    },

    isActivitySelected(row) {
      return this.checkedRows.findIndex((obj) => { return obj.id == row.id })
    },

    colorGroup(group){
      let defaultColor = "#F29B57"
      let configs = JSON.parse(group.configs)

      return configs && configs.color ? configs.color : defaultColor
    },

    nameGroup(group){
      return group.name.substring(0, 2).toUpperCase()
    }
   },
}
</script>
