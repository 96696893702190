<template>
  <div class="page-with-top-bar">

    <div class="top-bar flex justify-end">
      <div class="flex items-center text-white">
        <!-- Importar usuarios
        <v-button-icon-round
                @click="$router.push({name: 'admin.partners.import'})"
                icon="file-import"
                tooltip="import_groups"
                tooltip-position="left"
                class="mr-1"
        />
        -->
      </div>
    </div>

    <div class="w-full flex justify-between mt-4 below-top-bar" v-if="this.user.areas.can_admin">

      <div class="flex">
      </div>

      <div class="flex">
          <router-link
            to="/admin/partner/"
            class="btn btn-blue rounded"><b-icon icon="plus" pack="fas"/>
            {{ $t('add_partner') }}
          </router-link>
      </div>

    </div>

    <div class="panel">
      <div v-if="!loaded" class="text-center my-4">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="w-full flex flex-wrap mt-4">
        <section class="w-full">
          <b-table
          :data="partners"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          icon-pack="fas"
          v-if="loaded">
          <template>
            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="code" sortable>
              <template v-slot:header="">{{ $t('code') }}</template>
              <template v-slot="props">{{ props.row.code }}</template>
            </b-table-column>

            <b-table-column field="cif" sortable>
              <template v-slot:header="">{{ $t('cif') }}</template>
              <template v-slot="props">{{ props.row.cif }}</template>
            </b-table-column>

            <b-table-column field="type" sortable>
              <template v-slot:header="">{{ $t('type') }}</template>
              <template v-slot="props">{{ $t(types[props.row.type]) }}</template>
            </b-table-column>

            <b-table-column field="users" sortable>
              <template v-slot:header="">{{ $t('workers') }}</template>
              <template v-slot="props">{{ props.row.users_count }}</template>
            </b-table-column>

            <b-table-column field="machineries" sortable>
              <template v-slot:header="">{{ $t('machineries') }}</template>
              <template v-slot="props">{{ props.row.machineries_count }}</template>
            </b-table-column>



            <b-table-column field="actions" :label="$t('actions')" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="w-full h-full flex items-center justify-around">
                    <router-link :to="'/admin/partner/' + props.row.id" class="flex pr-0">
                      <b-tooltip :label="$t('edit')" type="is-primary" position="is-left">
                        <div class="cursor-pointer flex text-blue">
                          <b-icon icon="pencil-alt" pack="fas" />
                        </div>
                      </b-tooltip>
                  </router-link>

                  <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                      <div @click="deletePartnerButtonClicked(props.row.id, props.row.name)"
                          class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>
          <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.partners.length }}
          </template>

          <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from "vuex";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: 'auth',
  title: 'partners_page_title',
  subtitle: 'partners_page_subtitle',
  menuOption: '0-4',
  components: {
    ScaleLoader
  },

  data: () => ({
    partners: [],
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,
    types: ['partners_type_ute', 'partners_type_cliente', 'partners_type_subcontrata']
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    })
  },

  mounted () {
    this.getPartners()
  },

  methods: {

    async getPartners () {
      const { data } = await axios.get("/api/v2/user/partners", {'params' : { }});

      if (data.partners) {
        this.partners = data.partners;
        this.loaded = true
      }
    },

    deletePartnerButtonClicked(partnerId, partnerName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_partner_confirm_text', [partnerName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/partner/' + partnerId + '/delete'
          const {data} = await axios.post(url)
          if (data && data.success) {
            this.loaded = false
            this.$notify.success('success_deleting_partner')
            self.getPartners()
          } else {
            this.$notify.error(data.error)
          }
        }
      })
    },

  },


};
</script>
