<template>
  <div>
    <!-- Filtros -->
    <measurement-filters ref="filtersRef"
      @filters-updated="filtersUpdated"
      @epc-filter-orders-loaded="loadedOrders"/>
    <!-- Botones, sólo Jefes de departamento -->
    <div class="w-full">
      <div class="p-2- text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">
        <template v-if="project.user_department.can_admin || user.collaborator">

          <!-- Informes -->
          <project-assignments-report :openWithButton="false"/>

          </template>
      </div>

    </div>

    <div class="text-sm panel">
      <template>
        <!-- Contador de resultados -->
        <div v-if="total">
          <h4 class="text-right">
            <b>{{$t('count_results')}}: {{ total }}</b>
          </h4>
        </div>

        <b-table
          :data="list"
          :loading="isLoadingTable"
          icon-pack="fas"

          paginated
          backend-pagination
          :per-page="perPage"
          :total="total"
          @page-change="onPageChange"
          :current-page.sync="currentPage"

          backend-sorting
          :default-sort="orderField"
          :default-sort-direction="orderDir"
          @sort="onSort"

          detailed
          detail-key="id"

          class="text-xs border-blue-lighter"
          bordered
          narrowed
          striped

          :sticky-header="stickyHeader"
          :mobile-cards="false"
        >

          <template slot="empty">
            <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('empty_assignments_table_msg') }}</span>
            </div>
          </template>

          <template>

            <b-table-column field="type" :label="$t('type')" cell-class="vertical-center" sortable v-slot="items">
              {{ $t(items.row.type) }}
            </b-table-column>

            <b-table-column field="name" :label="$t('name')" cell-class="vertical-center" sortable v-slot="items">
              <span :class="canOpenEditModal(items.row) ? 'clickable' : ''" @click="onClickEdit(items.row)">{{ items.row.name }}</span>
            </b-table-column>

            <b-table-column field="unit_name" :label="$t('measurement_unit')" cell-class="vertical-center" sortable  v-slot="items">
              <span v-if="items.row.unit_name">{{ items.row.unit_name }}</span>
              <span v-else>---</span>
            </b-table-column>

            <b-table-column field="cost" :label="$t('price')" cell-class="vertical-center" centered sortable v-slot="items">
              <span v-if="items.row.unit_price != 0">
                {{ twoDecimals(items.row.cost) }} {{project.currency}}
              </span>
              <span v-else>---</span>
            </b-table-column>

            <b-table-column field="total_quantity" :label="$t('total_quantity')" cell-class="vertical-center" centered
                            sortable v-slot="items">
              <span v-if="!isTask(items.row)">{{ items.row.total_quantity }}</span>
              <span v-else>---</span>
            </b-table-column>

            <b-table-column field="executed_quantity" :label="$t('executed_quantity')" cell-class="vertical-center" sortable
                            centered v-slot="items">
              <span v-if="!isTask(items.row)">{{ items.row.executed_quantity }}</span>
              <span v-else>---</span>
            </b-table-column>

            <b-table-column field="remaining" :label="$t('remaining')" cell-class="vertical-center" centered sortable v-slot="items">
              {{ getRemaining(items.row) }}
            </b-table-column>

            <b-table-column field="completed" :label="$t('activity_status_done')" cell-class="vertical-center" centered
                            sortable v-slot="items">
              <b-tooltip
                :label="items.row.completed ? $t('completed'):$t('uncompleted')"
                position="is-top"
                v-html="isCompleted(items.row)"
              />
            </b-table-column>
            <b-table-column field="pending_validation" :label="$t('pending_validation_quantity')" cell-class="vertical-center" centered
                            sortable v-slot="items">
              <span v-if="items.row.type != 'certifier' && items.row.pending_validation_quantity && items.row.pending_validation_quantity != 0">
                {{ twoDecimals(items.row.pending_validation_quantity) }}
                <span @click="onClickValidate(items.row)">
                  <b-tooltip
                    :label="$t('validate')"
                    position="is-top"
                  >
                    <b-icon pack="fas" icon="thumbs-up"></b-icon>
                  </b-tooltip>
                </span>
              </span>
              <span v-else>---</span>
            </b-table-column>

            <b-table-column field="partner" :label="$t('partner')" cell-class="vertical-center" sortable v-slot="items">
              <span>{{ getNamePartner(items.row.work_order_id) }}</span>
            </b-table-column>

            <b-table-column field="purchase_order" :label="$t('purchase_order')" cell-class="vertical-center" sortable v-slot="items">
              <span>{{ items.row.order_code }}</span>
            </b-table-column>

            <b-table-column field="position" :label="$t('position')" cell-class="vertical-center" sortable v-slot="items">
              <span>{{ items.row.position }}</span>
            </b-table-column>
          </template>

          <template slot="detail" slot-scope="items">

            <b-table
              :data="[items.row.assignable]"
              icon-pack="fas"

              class="text-xs border-blue-lighter"
              :class="isSubActivity(items.row.assignable) ? 'subactivities' : ''"
              bordered
              narrowed
              striped
            >
              <template>
                <b-table-column field="planning_code" :label="$t('code')" cell-class="w-32 vertical-center"
                  v-slot="activities">
                  <div>
                    {{ activities.row.planning_code }}
                  </div>
                </b-table-column>

                <b-table-column field="name" :label="$t('name')" cell-class="vertical-center" v-slot="activities">

                  <div class="cursor-pointer"
                       @click="openActivity(activities.row)">
                    <activity-time-status :date="activities.row.current_end_date" class="mr-2"
                                          v-if="activities.row.status != 4 && activities.row.status != 6"/>
                    <b-tooltip v-if="activities.row.critical_path != null" position="is-right"
                               :label="$t('critical_path')">
                      <b-icon class="fa is-small fa-project-diagram" style="color:#e3342f"/>
                    </b-tooltip>
                    {{ activities.row.name }}
                  </div>
                </b-table-column>

                <b-table-column field="progress" :label="$t('progress_label')" cell-class="w-32 vertical-center" centered
                  v-slot="activities">
                  <progress-bar
                    :step="activities.row.progress"
                    color="blue-light"
                    class="w-full"
                  />
                </b-table-column>

                <b-table-column field="current_start_date" :label="$t('start')" cell-class="w-25 vertical-center" centered
                  v-slot="activities">
                  {{ activities.row.current_start_date }}
                </b-table-column>

                <b-table-column field="current_end_date" :label="$t('end')" cell-class="w-25 vertical-center" centered
                  v-slot="activities">
                  {{ activities.row.current_end_date }}
                </b-table-column>

                <b-table-column field="status" :label="$t('status')" cell-class="w-32 vertical-center" centered
                  v-slot="activities">
                  <activity-status-label :status="activities.row.status" class="w-full"
                                         style="vertical-align: middle"/>
                </b-table-column>
              </template>
            </b-table>
          </template>

        </b-table>
      </template>
    </div>

    <!-- Modal para Editar -->
    <assignment-update-modal
      ref="assignment_update_modal"
      entity-type="activity"
      :entity-id="entityId"
      @reload="reload"
    />

    <!-- Actualiza la tabla automáticamente, de momento se queda -->
    <!-- <mugen-scroll :handler="fetchData" :should-handle="!isLoadingTable" scroll-container="wrap"></mugen-scroll>
    <b-loading :is-full-page="false" :active.sync="isLoadingTable" :can-cancel="false"></b-loading> -->
      <assignment-validate-quantity-modal
        ref="assignment_validate_quantity_modal"
        entity-type="activity"
        :entity-id="entityId"
      />
  </div>
</template>

<script>
  import Vue from "vue"
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import MugenScroll from 'vue-mugen-scroll'
  import Buefy from 'buefy'
  import BIcon from "buefy/src/components/icon/Icon";
  import EventBus from '~/plugins/bus'
  import assignmentUpdateModal from "·/components/assignment-update-modal";
  import activityTimeStatus from '·/components/activity-time-status'
  import activityStatusLabel from '·/components/activity-status-label'
  import MeasurementFilters from '·/components/measurement-filters';
  import projectAssignmentsReport from '·/components/project-assignments-report';
  import AssignmentValidateQuantityModal from '·/components/assignment-validate-quantity-modal';

  Vue.use(Buefy);

  export default {
    middleware: 'auth',
    title: 'measurements_page_title',
    subtitle: 'measurements_page_subtitle',
    menuOption: '2-6',

    components: {
      BIcon,
      MugenScroll,
      assignmentUpdateModal,
      activityTimeStatus,
      activityStatusLabel,
      MeasurementFilters,
      projectAssignmentsReport,
      AssignmentValidateQuantityModal
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        project: 'app/project',
        mobile: 'device/mobile'
      }),

      stickyHeader() { return this.mobile.on_mobile}, // para fiar el header de la tabla cuando se esta mostrando en movil
    },

    data: () => ({
      // paginationRows: 10,
      entityId: 0, // ID de la actividad clicada
      isLoadingTable: true,
      page: 1,
      currentPage: 1,
      last: 1,
      perPage: 25,
      total: 0,
      orderField: "name",
      orderDir: "desc",
      list: [],
      filterPendingValidation: null,

      orders: [] // es cargado desde el componente measurement-filters
    }),

    created() {

    },

    mounted() {
      this.applyFilters(this.page);
      let self = this;

      EventBus.$on("refreshAssignmentChangedNotifications", function (e) {
        if (self.project.id === e.project_id) {
          self.isLoading = true;
          self.applyFilters(self.page);
        }
      });
      EventBus.$on("refreshAssignmentCreatedNotifications", function (e) {
        if (self.project.id === e.project_id) {
          self.isLoading = true;
          self.applyFilters(self.page);
        }
      });
      EventBus.$on("refreshAssignmentDeletedNotifications", function (e) {
        if (self.project.id === e.project_id) {
          self.isLoading = true;
          self.applyFilters(self.page);
        }
      });
    },

    beforeDestroy(){
      EventBus.$off("refreshAssignmentChangedNotifications");
  	  EventBus.$off("refreshAssignmentCreatedNotifications");
  	  EventBus.$off("refreshAssignmentDeletedNotifications");
    },

    methods: {
      async getList(args = []) {

        // const {data} = await axios.get('/api/v2/project/' + this.project.id + '/assignments?page=' + this.page, {params: args});
        const {data} = await axios.get('/api/v2/project/' + this.project.id + '/assignments', {params: args});

        // Last and total
        this.last = data.lastPage;
        this.total = data.total;

        if (data && data.assignments) {
          this.list = data.assignments;
        } else {
          this.$notify.error('error_loading_assignment_data');
        }

        this.isLoadingTable = false;
      },

      customizeFilters() {
        let filters = this.$refs.filtersRef.generateFilters();
        filters.with_parent = 1;
        filters.with_assignments = 1;
        filters.order_field = this.orderField;
        filters.order_dir = this.orderDir;
        filters.per_page = this.perPage;
        if( typeof filters.where_pending_validation === 'undefined' &&  this.$route.query.pending_validation ) {
          filters.where_pending_validation = 'pending'
        }
        return filters;
      },

      /**
       * Cambia la ventana a la tabla de actividades normal
       */
      displayTable() {
        this.$router.push({name: 'project.activities'});
      },

      displayKanban() {
        this.$router.push({name: 'project.activities.kanban'});
      },

      displayGantt() {
        this.$router.push({name: 'project.activities.gantt'});
      },

      filtersUpdated() {
        this.isLoadingTable = true;
        this.applyFilters(1);
      },

      applyFilters(page) {
        let args = this.customizeFilters();
        args.page = (page <= this.last) ? page : 1;
        this.currentPage = args.page;
        this.getList(args);
      },

      fetchData() {
        if (this.page <= this.last) {
          this.isLoadingTable = true;
          this.applyFilters(this.page);
        }
      },

      onPageChange(page) {
        this.page = page;
        this.fetchData()
      },

      onSort(field, order) {
        this.orderField = field;
        this.orderDir = order;
        this.fetchData()
      },

      sortByCompleted(a, b, isAsc) {
        return isAsc ?
          a.completed - b.completed :
          b.completed - a.completed
      },

      isCompleted(row) {
        if (row.completed)
          return '<i class="fas fa-check text-green"/>';

        return '<i  class="fas fa-times text-red"/>';

      },

      // Modal para confirmar cantidades ejecutadas
      onClickValidate(row) {
        // User es usuario certificador del proyecto
        // if (!this.apiProject.can_validate)
        // if (!this.project.can_validate)
        //   return;

        // this.entityId = row.parentId;
        this.$refs.assignment_validate_quantity_modal.show(row.id);
      },

      getRowClass() {
        return '';
      },

      getAssignmentStatusColor(row) {
        switch (row.alert) {
          case -1:
            return 'bg-blue';

          case 0:
            return 'bg-green';

          case 1:
            return 'bg-red';

          default:
            return 'bg-blue';
        }
      },

      getAssignmentStatusLabel(row) {
        switch (row.alert) {
          case -1:
            return 'assignments-under';

          case 0:
            return 'assignments-completed';

          case 1:
            return 'assignments-over';
        }
      },

      /**
       * Calcula el estado de la medición
       * @param row
       * @returns {number}
       */
      getAssignmentStatus(row) {
        if (row.type === 'task')
          if (row.completed)
            return 0; // perfecto
          else return -1;// por debajo, tarea aun no completada

        if (row.executed_quantity < row.total)
          return -1; // por debajo
        else if (row.executed_quantity > row.total)
          return 1; // por encima
        else return 0; // cantidad exacta
      },

      /**
       * Comprueba si puede abrir el modal de editado para una fila en concreto
       */
      canOpenEditModal(row) {
        if (row.user_id != this.user.id && this.isTask(row)) return false;
        return true;
      },

      /**
       * Abre el modal para editar una medición
       */
      onClickEdit(row) {
        if (!this.canOpenEditModal(row)) {
          return;
        }
        this.entityId = row.assignable_id;
        this.$refs.assignment_update_modal.show(row);
      },

      openActivity(activity) {
        if (!activity.parent_id) {
          this.$router.push({name: 'activity.home', params: { activity_id: activity.id } } )
        } else {
          this.$router.push({
            name: 'subactivity.home',
            params: { activity_id: activity.parent_id, subactivity_id: activity.id }
          })
        }

      },

      getRemaining(row) {
        if (this.isTask(row)) return "---";

        if (row.completed)
          return this.twoDecimals(0);

        return this.twoDecimals(row.total_quantity - row.executed_quantity);
      },

      isSubActivity(row) {
        return row.parent_id != null;
      },

      isTask(row) {
        if (row.type === 'task')
          return true;
      },

      reload(data) {
        self.isLoading = true;
        this.applyFilters(this.page);
      },

      /**
       * Convierte el numero a dos decimales obligatoriamente
       * @param number
       * @returns {string}
       */
      twoDecimals(number) {
        if (number == null)
          number = 0;
        return parseFloat(number).toFixed(2);
      },

      loadedOrders(data) {
        this.orders = data
      },

      getNamePartner(idOrder) {

        if (! idOrder) return null
        let name = null
        let order = this.orders.find(o => idOrder == o.id)
        if (order) {
          let partners = order.partners.map(p => p.name )
          name = partners.join(',')
        }

        return name
      }

    },

  };
</script>

<style lang="scss" scoped>
  .panel::v-deep .b-table .table-wrapper.has-sticky-header tr:first-child th {
    background: hsl(220, 43%, 53%);
  }
  .vertical-center {
    vertical-align: inherit !important;
  }

  .clickable {
    cursor: pointer;
  }

  .subactivities {
    .table th {
      background-color: #89a4d6;
    }

    &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
      background-color: #89a4d6 !important;
    }
  }

  .b-table .table-wrapper.has-sticky-header tr:first-child th {
    // background-color: $primary !important;
    min-height: 3rem;
  }
</style>
