<template>
    <div>
        <!-- Table assignment by breakdown -->
        <div class="panel">
            <b-table :data="budgets" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
                :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
                :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas"
                detailed detail-key="activity_id" :show-detail-icon="showDetailIcon" scrollable>
                <template>
                    <b-table-column field="code" :label="$t('code')" sortable v-slot="props" width="20%">
                        {{ props.row.planning_code }}
                    </b-table-column>
                    <b-table-column field="name" :label="$t('Nombre de actividad')" sortable v-slot="props">
                        {{ props.row.activity_name }}
                    </b-table-column>
                </template>
                <template #detail="props">
                    <div v-if="props.row.assignments.length > 0">
                        <h3 class="font-bold">{{ $t('assignments') }}</h3>
                        <b-table :data="props.row.assignments" :loading="isLoadingTable" backend-sorting class="w-full"
                            striped bordered icon-pack="fas" detailed detail-key="assignment_id"
                            :show-detail-icon="showDetailIcon" scrollable>
                            <template>
                                <b-table-column field="assignment_code" :label="$t('Codigo Medicion')" sortable
                                    v-slot="data" style="background: red;">
                                    {{ data.row.assignment_code }}
                                </b-table-column>
                                <b-table-column field="assignment_name" :label="$t('name')" sortable v-slot="data">
                                    {{ data.row.assignment_name }}
                                </b-table-column>
                                <b-table-column field="unit" :label="$t('measurement_unit')" sortable v-slot="data">
                                    {{ data.row.unit }}
                                </b-table-column>
                                <b-table-column field="total_units" :label="$t('assignment')" sortable v-slot="data">
                                    {{ formatNumber(data.row.assignment_total_units) }}
                                </b-table-column>
                                <b-table-column field="weight" :label="$t('weight')" sortable v-slot="data">
                                    {{ formatNumber(data.row.assignment_weight) }}
                                </b-table-column>
                                <b-table-column field="unit_price" :label="$t('unit_price')" sortable v-slot="data">
                                    {{ formatNumber(data.row.assignment_unit_price) }}
                                </b-table-column>
                            </template>
                            <template #detail="data">
                                <h3 class="font-bold">{{ $t('breakdown') }}</h3>
                                <b-table :data="data.row.cost_budgets" :loading="isLoadingTable" backend-sorting
                                    class="w-full" striped bordered icon-pack="fas" scrollable>
                                    <b-table-column field="code" :label="$t('code')" sortable v-slot="data">
                                        {{ data.row.code }}
                                    </b-table-column>
                                    <b-table-column field="name" :label="$t('breakdown_name')" sortable v-slot="data">
                                        {{ data.row.name }}
                                    </b-table-column>
                                    <b-table-column field="provider" :label="$t('provider')" sortable v-slot="data">
                                        {{ data.row.provider.name }}
                                    </b-table-column>
                                    <b-table-column field="unit_price" :label="$t('unit_price')" sortable v-slot="data"
                                        class="text-center">
                                        {{ formatNumber(data.row.unit_price) }}
                                    </b-table-column>
                                    <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="data"
                                        class="text-center">
                                        {{ formatNumber(data.row.quantity) }}
                                    </b-table-column>
                                    <b-table-column field="coefficient" :label="$t('coefficient')" sortable
                                        v-slot="data" class="text-center">
                                        {{ formatNumber(data.row.coefficient) }}
                                    </b-table-column>
                                    <b-table-column field="total_amount" :label="$t('total_price')" sortable
                                        v-slot="data" class="text-center">
                                        {{ formatNumber(formatPrice(data.row.unit_price, data.row.quantity)) }}
                                    </b-table-column>
                                    <b-table-column :label="$t('actions')" field="actions" centered width="150"
                                        v-slot="data">
                                        <div class="w-full h-full flex items-center justify-around">
                                            <b-tooltip :label="$t('edit_breakdown')" position="is-left">
                                                <div @click="editButtonClicked(data.row)"
                                                    class="cursor-pointer flex text-blue">
                                                    <b-icon pack="fas" icon="pencil-alt" />
                                                </div>
                                            </b-tooltip>
                                            <b-tooltip :label="$t('delete_breakdown')" position="is-left"
                                                type="is-danger">
                                                <div @click="removeResources(data.row)"
                                                    class="cursor-pointer flex text-red">
                                                    <b-icon pack="fas" icon="trash-alt" />
                                                </div>
                                            </b-tooltip>
                                        </div>
                                    </b-table-column>
                                </b-table>
                            </template>
                        </b-table>
                    </div>
                </template>
                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        projectId: { type: Number, require: true },
    },
    computed: {
        filters() {
            let filters = {
                'order_field': this.orderField,
                'order_dir': this.orderDir,
                'perPage': this.perPage,
                'page': this.page,
                'project_id': this.projectId
            }
            return filters
        },
    },
    data: function () {
        return {
            isLoadingTable: true,
            budgets: [],
            perPage: 25,
            orderField: 'unit_price',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
            applyFilters: {},
            showDetailIcon: true,
        }
    },
    created() {
        this.getAssignmentBreakdown()
    },
    methods: {
        async getAssignmentBreakdown(filters = null) {
            this.isLoadingTable = true
            this.budgets = []
            const params = filters ? filters : this.filters;
            const { data } = await axios.get(`/api/v2/assignment-breakdown/`, { params });
            if (data && data.success) {
                this.budgets = data.activities;
                this.last = data.pagination.lastPage;
                this.total = data.pagination.total;
                this.isLoadingTable = false
            }
        },

        onSort(field, order) {
            this.companyId = this.company.id
            this.orderField = field
            this.orderDir = order
            this.getBudgets();
        },

        onPageChange(page) {
            this.page = page
            this.getBudgets();
        },

        reload(arg = {}) {
            let filters = { ...arg, ...this.filters }
            this.getAssignmentBreakdown(filters);
        },

        async editButtonClicked(entity) {
            this.$emit('epc-edit', entity)
        },

        async removeResources(budget) {
            try {
                this.$dialog.confirm({
                    title: this.$t('delete_breakdown'),
                    message: this.$t('delete_activity_confirm_text', [budget.name]),
                    confirmText: this.$t('remove_resource'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        this.processingData = true;
                        await axios.delete(`/api/v2/assignment-breakdown/${budget.id}/${budget.assignment.assignment_id}`);
                        this.$buefy.toast.open({
                            message: this.$t("removed_successfully"),
                            type: 'is-success'
                        });
                        this.reload();
                    },
                    onCancel: () => {
                        this.processingData = false;
                    }
                });
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t("remove_error") + (error.response.data.message),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },

        archiveClass(value) {
            return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
        },

        handleSelect(newRow) {
            this.$emit('update:template', newRow);
        },

        clearSelected() {
            this.rowSelected = null
        },

        // showQuantity(quantity) {
        //     return parseQuantity(quantity);
        // },
        formatPrice(unitPrice, quantity) {
            if (!unitPrice || !quantity) return '0.00';
            const total = unitPrice * quantity;
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(total);
        },
        formatNumber(value) {
            const number = parseFloat(value);
            if (isNaN(number)) return '0,00';
            let [integerPart, decimalPart] = number.toFixed(2).split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${integerPart},${decimalPart}`;
        }
    },
}
</script>
<style scoped>
.custom-class {
    background-color: red !important;
}
</style>