<template>
  <section :class="{'inline-block': !openWithButton}">
    <button-menu v-if="!openWithButton"
      :text-label="$t('generate_report')"
      :icon="'file-excel'"
      :active-tooltip="true"
      :positionTooltip="'is-left'"
      :redirect="null"
      :handle-on-click="() => { isModalActive = true }"
    />
    <div v-if="openWithButton" class="mr-2 rounded-sm" @click="isModalActive = true">
      <v-button icon="file-excel" class="mr-4"></v-button>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('generate_report') }}</p>
        </header>
        <section class="modal-card-body">
          <!-- Cuerpo del Modal-->
          <div class="w-1/2 mb-4 mr-4">
            <b-field :label="$t('start_date')">
              <b-datepicker
                icon-pack="fas" 
                v-model="start_date"
                :placeholder="$t('scheduled_start_date')"
                :month-names="$t('months_names')"
                :day-names="$t('days_names')"
                :first-day-of-week="parseInt($t('first_day_of_week'))"
                :readonly="false"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="w-1/2 mb-4 mr-4" >
            <b-field :label="$t('end_date')">
              <b-datepicker
                icon-pack="fas" 
                v-model="end_date"
                :placeholder="$t('scheduled_end_date')"
                :month-names="$t('months_names')"
                :day-names="$t('days_names')"
                :first-day-of-week="parseInt($t('first_day_of_week'))"
                :readonly="false"
              >
              </b-datepicker>
            </b-field>
          </div>

          <b-checkbox v-if="company.alias == 'tsk'
            || company.alias == 'x-elio'
            || company.alias == 'welink'
            || company.alias == 'tci'" v-model="includeIssues" class="mb-2 text-left w-full">
              {{ $t('include_issues') }}
          </b-checkbox>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading" v-on:click="generateReport">{{ btnGenerateReport }}
          </button>
          <button class="button" size="is-small" type="button" @click="isModalActive = false">{{ $t('close') }}</button>
        </footer>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import moment from 'moment';
  import ButtonMenu from '../components/quick-button-menu/components/button-menu.vue'

  export default {
    components: {
      ButtonMenu
    },

    props: {
      openWithButton: {  type: Boolean, default: true }
    },

    data() {
      return {
        start_date: null,
        end_date: null,
        btnGenerateReport: this.$t('generate_report'),
        isModalActive: false,
        isLoading: false,
        includeIssues: true,
      }
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        company: 'app/company',
      }),
    },


    methods: {

      async generateReport() {

        let params = {
          project_id: this.project.id,
          start_date: moment(this.start_date).format('YYYY-MM-DD'),
          end_date: moment(this.end_date).format('YYYY-MM-DD'),
          include_issues: +this.includeIssues
        };

        // Generando reporte, cerramos el modal
        this.$notify.success('generating_report');
        this.isModalActive = false;

        //Encargamos el reporte al servidor
        const {data} = await axios.get('/api/v2/report/generate_excel', {params: params});

        //si nos llega un reporte hay que descargarlo
        if (data) {

          // Descargamos
          window.open(data.data);

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = this.$t('generate_report');
          this.isModalActive = false;

          // Reporte generado
          this.$notify.success('report_generated')

        } else {

          // Error
          this.$notify.error('error_loading_report')

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = 'Generar Informe';

        }
      },
      sizeOfList() {
        return parseInt(this.totalPeriods);
      },

    }
  }
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 480px;
    min-width: 560px;
  }
</style>
