<template>
  <div>
    <label class="font-bold">Acción masiva: {{ action.title }}</label>

    <div class="panel">
      <p v-if="action.name === 'assign_manager'">La acción asignará a {{ linkedEntities.length }} usuario como responsable de {{ activities.length }} actividad/es</p>
      <p v-else-if="action.name === 'assign_participants'">La acción asignará a {{ linkedEntities.length }} participante/s a {{ activities.length }} actividad(es) seleccionada(s)</p>
      <p v-else-if="action.name === 'assign_collaborators'">La acción asignara a {{ linkedEntities.length }} colaborador/es a {{ activities.length }} actividad(es) seleccionada(s)</p>
      <p v-else-if="action.name === 'assign_tracking_user'">La acción asignara a {{ linkedEntities.length }} usuario/s de seguimiento a {{ activities.length }} actividad(es) seleccionada(s)</p>
      <p v-else-if="action.name === 'link_documents'">La acción vinculará a {{ linkedEntities.length }} documento/s a {{ activities.length }} actividad(es) seleccionada(s)</p>
      <p v-else-if="action.name === 'link_validations'">La acción vinculará a {{ linkedEntities.length }} flujo/s de validacion/es a {{ activities.length }} actividad(es) seleccionada(s)</p>


      <div class="flex flex-row mt-3 space-x-4">
        <div class="w-1/2">
          <div v-if="action.name === 'change_data'">
            <p>Datos a ser modificados</p>
            <ul class="max-h-80 overflow-y-auto pr-2">
              <li>
                <p>Fecha actual de inicio: {{ linkedEntities[0].current_start_date }}</p>
              </li>
              <li>
                <p>Fecha actual de finalización: {{ linkedEntities[0].current_end_date }}</p>
              </li>
            </ul>
          </div>
          <div v-else-if="action.name === 'assign_group'">
            <p>Grupo a asignar</p>
            <ul class="max-h-80 overflow-y-auto pr-2">
              <li>
                <p>Nombre grupo: {{ linkedEntities[0].group_name }}</p>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="mb-2" v-if="action.name === 'assign_manager'">Responsable a asignar</p>
            <p class="mb-2" v-else-if="action.name === 'assign_participants'">Participante(s) a asignar</p>
            <p class="mb-2" v-else-if="action.name === 'assign_collaborators'">Colaborador(es) a asignar</p>
            <p class="mb-2" v-else-if="action.name === 'assign_tracking_user'">Usuario(s) de seguimiento de actividad a asignar</p>
            <p class="mb-2" v-else-if="action.name === 'link_documents'">Documento(s) a vincular</p>
            <p class="mb-2" v-else-if="action.name === 'link_validations'">Validacion(es) a vincular</p>
            <p class="mb-2" v-else-if="action.name === 'link_tags'">Etiquetas a ser vinculadas</p>

            <entity-list
              :entities="linkedEntities"
              :entityKey="entityKey"
              :withImage="withImage"
              :show="true"
              :showRemoveButton="false"
              :componentName="componentName"
            />
          </div>
        </div>
        <div class="w-1/2">
          <p class="mb-2">Actividad(es) afectada(s)</p>
          <entity-list
              :entities="activities"
              entityKey="id"
              :withImage="false"
              :show="true"
              :showRemoveButton="false"
              :componentName="['name']"
            />
        </div>
      </div>
      <div class="mt-8">
        <p class="mb-4">
          ¿Aplicar acción solo a actividades seleccionadas?
          <b-tooltip position="is-bottom" multilined>
            <b-icon pack="fas"
                type="is-info"
                icon="info-circle"
                size="is-small">
            </b-icon>
            <template v-slot:content>
                Por defecto la acción se aplica a las actividades padres seleccionadas
                <b>y todas las subactividades de estas</b>. <br>
                Marque esta opción si desea que la acción
                <i>afecte exclusivamente a las actividades y subactividades seleccionadas</i>.
            </template>
        </b-tooltip>

          <b-field>
            <b-switch v-model="applyOnlySelected" type="is-success"></b-switch>
          </b-field>
        </p>
        <p class="font-bold">¿Seguro que desea continuar?</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
import entityList from './utils/entity-list.vue';

Vue.use(moment)

export default {
  name: 'wizard-bulk-complete',

  components: {
    entityList
  },

  props: {
    projectId: { type: Number, default: 0 },
    action: { type: Object, required: true },
    activities: { type: Array, required: true },
    subactivities: { type: Array, default: () => [] },
    linkedEntities: { type: Array, required: true },
  },

  data: () => ({
    form: new Form({ }),
    boxFullWidth: false,
    applyOnlySelected: false
  }),

  mounted() {
  },

  created() {

  },

  watch: {

  },

  computed: {
    withImage() {
      return this.isListUser()
    },

    componentName() {
      return this.getComponentName()
    },

    entityKey() {
      let keyVal = '';
      switch (this.action.name) {
        case 'link_documents':
          keyVal = 'document_id'
          break;

        case 'link_tags':
          keyVal = 'text'
          break;

        default:
          keyVal = 'id'
          break;
      }

      return keyVal
    }
  },

  methods: {
    prepareForm() {

      this.form.activities = this.activities.map((activity) => {
        // return activity.planning_code
        return activity.id
      })

      // añadimos los id de las subactividades si solo se aplica a las actividades selecionadas
      if(this.applyOnlySelected && this.subactivities.length) {
        let subactivitites = this.subactivities.map((subactivity) => {
          return subactivity.id
        })
        console.log('subactivities: ', subactivitites)
        this.form.activities.push(...subactivitites)
      }
      console.log('activities .form: ', this.form.activities)
      switch (this.action.name) {
        case 'assign_manager':
        case 'assign_participants':
        case 'assign_collaborators':
        case 'assign_tracking_user':

          this.form.linkedEntities = this.linkedEntities.map((linkedenty) => {
            return linkedenty.email
          })
          break;

        case 'change_data':
          this.form.linkedEntities = this.linkedEntities
          break;

        case 'link_documents':
        case 'link_validations':
          this.form.linkedEntities = this.linkedEntities.map((linkedenty) => {
            return linkedenty.code
          })

          break;

        case 'link_tags':
          this.form.linkedEntities = this.linkedEntities.map((linkedenty) => {
            return linkedenty.text
          })
          break;

        case 'assign_group':
          this.form.linkedEntities = this.linkedEntities
          break;

        default:
          break;
      }

      this.form.action_type = this.action.type
      this.form.apply_only_selected = this.applyOnlySelected
    },

    async sendForm() {
      this.prepareForm();
      let url = `/api/v2/project/${this.projectId}/bulk-actions`

      // Enviamos el form
      const { data } = await this.form.post(url)
      if (data && data.success) {
        this.$notify.success('bulk_action_added_process')
      } else {
        this.$notify.error('error_bulk_action')
      }
    },

    isListUser() {
      return this.action.name === 'assign_manager' || this.action.name === 'assign_participants' || this.action.name === 'assign_collaborators'
    },

    getComponentName() {

      let arrName = [];
      switch (this.action.name) {
        case 'assign_manager':
        case 'assign_participants':
        case 'assign_collaborators':
          arrName = ['name', 'surname']
          break;

        case 'link_tags':
          arrName = ['text']
          break;

        default:
          arrName = ['name']
          break;
      }

      return arrName;
    }
  },
}

      // {value: 1, text: 'assign_manager', title: 'asignación de responsable'},
      // {value: 2, text: 'assign_participants', title: 'asignación de participantes'},
      // {value: 2, text: 'assign_partners', title: 'asignación de colaboradores'},
      // {value: 3, text: 'modify_date_start_end', title: 'cambio de fechas de inicio/fin'},
      // {value: 4, text: 'link_documents', title: 'vinculación de documentos'},
      // {value: 5, text: 'add_validation_flow', title: 'asignación de fluo de validaciones'},
      // {value: 6, text: 'tags', title: 'asignación de etiquetas'},
      // {value: 7, text: 'geolocation', title: 'asignación de geolocalización'},
</script>
