<template>
  <div v-if="action">
    <div v-if="showDescription">
      <label class="font-bold">Acción masiva: {{ action.title }}</label>
      <p v-if="action.name == 'assign_manager'">Seleccione el usuario que se asignara como responsable de las actividades seleccionadas</p>
      <p v-else>Seleccione los usuarios que se asignaran como {{ collaborator ? 'colaboradores' : 'participantes' }} de las actividades seleccionadas</p>
    </div>

    <div class="flex flex-row space-x-4">
      <div class="panel">

        <form @submit.prevent="usersFilter">
          <div class="w-full justify flex">
            <b-field :label="$t('search_by_name')" class="w-2/5 mr-4" :label-position="'on-border'">
              <b-input v-model="userFilter.name" size="is-small"></b-input>
            </b-field>
            <b-field :label="$t('search_by_email')" class="w-2/5 mr-4" :label-position="'on-border'">
              <b-input v-model="userFilter.email" size="is-small"></b-input>
            </b-field>
            <b-field :label="$t('search_by_area')" class="w-2/5" :label-position="'on-border'">
              <b-select placeholder="select_area" v-model="userFilter.area" size="is-small" expanded>
                <option
                  v-for="option in areas"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
              <p class="control">
                <b-button class="button is-primary" size="is-small" nativeType="submit"><b-icon icon="search" pack="fas"/></b-button>
              </p>
            </b-field>

            <!-- <b-field label="" class="w-1/5 mt-4">
              <b-button class="btn btn-primary"  nativeType="submit"><b-icon icon="search" pack="fas"/></b-button>
            </b-field> -->
          </div>
        </form>

        <div class="text-sm w-full">

          <b-table
            icon-pack="fas"
            :data="users"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            class="text-xs border-blue-lighter"
            :loading="isLoadingTable"

            :focusable="action.name == 'assign_manager'"
            :selected.sync="selected"
            :is-row-selectable="(row) => { return action.name == 'assign_manager' }"
            @select="handleSelect"

            :checkable="action.name == 'assign_participants' || action.name == 'assign_collaborators' || action.name == 'assign_tracking_user' || checkable"
            :checked-rows.sync="checkedRows"
            @check="handleCheck"
            @check-all="handleCheckAll"

            :row-class="assignRowClass"
          >
            <template>
              <b-table-column field="avatar" label="Avatar" width="40" v-slot="props">
                <figure class="media-left">
                  <p class="image is-50x50">
                    <img :src="( action.name == 'assign_tracking_user' ? props.row.basic.avatar : props.row.avatar)" class="w-6 h-6 overflow-hidden rounded-full">
                  </p>
                </figure>
              </b-table-column>

              <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="email" :label="$t('email')" sortable v-slot="props">
                {{ props.row.email }}
              </b-table-column>

              <b-table-column field="area" :label="$t('area')" sortable v-slot="props">
                <template v-if="props.row.area">
                  {{ props.row.area.name }}
                </template>
              </b-table-column>

              <b-table-column :label="$t('user')" width=100 v-slot="props">
                <div class="w-full h-full flex items-center justify-around">
                  <!-- <b-icon v-show="props.row.active" pack="fas" class="flex text-green" icon="check"></b-icon>
                  <b-icon v-show="! props.row.active" pack="fas" class="flex text-red" icon="times"></b-icon> -->
                  <b-icon pack="fas" v-show="! props.row.collaborator && !props.row.guest" class="flex" icon="user"></b-icon>
                  <b-icon v-show="props.row.collaborator && !props.row.guest" pack="fas" class="flex" icon="user-friends"></b-icon>
                  <b-icon v-show="props.row.guest" pack="fas" class="flex" icon="user-check"></b-icon>
                </div>
              </b-table-column>
            </template>

            <template slot="bottom-left">
              <div v-show="userFilter.active === 'all'">
                <b-icon pack="fas" class="flex text-blue" icon="user"></b-icon>
                <b>{{ $t("total_users") }}</b>: {{ this.users.length }}
              </div>
              <div v-show="userFilter.active === 'active'">
                <b-icon pack="fas" class="flex text-green" icon="check"></b-icon>
                <b>{{ $t("total_active_users") }}</b>: {{ this.users.length }}
              </div>
              <div v-show="userFilter.active === 'inactive'">
                <b-icon pack="fas" class="flex text-red" icon="times"></b-icon>
                <b>{{ $t("total_inactive_users") }}</b>: {{ this.users.length }}
              </div>
            </template>

            <template slot="empty">No results</template>

          </b-table>
        </div>
      </div>

      <div class="panel" v-if="action.name == 'assign_participants' || action.name == 'assign_collaborators' || action.name == 'assign_tracking_user' || checkable">
        <div>
          <h6>{{collaborator ? 'Colaboradores' : 'Participantes' }} seleccionados:</h6>
          <entity-list
            :entities="checkedRows"
            entityKey="id"
            :withImage="true"
            :show="true"
            :componentName="['name', 'surname']"
            @removeButtonClicked="onClickRemoveButton($event)"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import api from '~/api'
import entityList from './utils/entity-list.vue';

export default {
  components: { entityList },

  props: {
    projectId: { type: Number, require:false },
    action: {type: Object, default: null },
    collaborator: {type: Boolean, default: false },
    withCollaborators: {type: Boolean, default: false },
    checkable: {type: Boolean, default: false },
    showDescription: {type: Boolean, default: true }
  },


  data: () => ({
    ready: false,
    showInactive: false,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    users: [],
    isLoadingTable: false,
    areas: null,
    projects: null,
    collaboratorPage: false,
    activeUsersFilter: 'active',
    userFilter: new Form ({
      name: '',
      email: '',
      area: 0,
      project:0,
      collaborator: false,
      active: 'active',
    }),

    selected: null,
    checkedRows: [],

    boxFullWidth: true,
    iconRemoveHovered: null,
  }),

  mounted () {
    let self = this
    api.fetchUserAreas().then(data => { self.areas = data.areas })
    this.emptyFilter()
    this.usersFilter()
  },

  watch:{
    collaborator(newCollaborator, oldCollaborator) {
      this.emptyFilter()
      this.usersFilter()
    },

    action(newAction, oldAction) {
      this.checkedRows = []
    }
  },

  methods: {

    async usersFilter () {
      if (! this.userFilter) {
        return;
      }

      this.isLoadingTable = true;

      this.userFilter.collaborator = this.collaborator
      this.userFilter.include_collaborators = this.action.name == 'assign_participants' || this.withCollaborators// incluira todos los usuarios anula el filtro de collaborator
      this.userFilter.include_guests = true // incluira todos los usuarios anula el filtro de collaborator

      let dataResponse = null;
      if ( this.action.name == 'assign_tracking_user' ) {
        const { data } = await axios.get(`/api/v2/project/${this.projectId}/users?r=activity_viewer&basic=0`)
         if (data.users_list && data.success) {
          dataResponse = data.users_list;
        }
      } else {
        const { data } = await this.userFilter.post('/api/v2/users/filter')
        if (data.data && data.success) {
          dataResponse = data.data;
        }
      }

      if (dataResponse) {
        this.users = []
        this.users = dataResponse
      }

      this.isLoadingTable = false;
    },

    async getProjects(){
      const url = '/api/v2/project/' + this.area + '/areas'
      const { data } = await axios.get(url)
      if (data.data) {
        this.projects = data.data
      }
    },
    emptyFilter(){
      this.userFilter.name = ''
      this.userFilter.email = ''
      this.userFilter.area = 0
      this.userFilter.project = 0
    },

    onClickRemoveButton(index) {
      this.checkedRows.splice(index, 1)
      // this.dispatchSelected(this.checkedRows)
      // this.$emit('removeButtonClicked', index)
    },

    handleSelect(row, oldRow) {
      this.dispatchSelected([row])
    },

    handleCheck(checkedList, row) {
      this.dispatchSelected(checkedList)
    },

    handleCheckAll(checkedList) {
      this.dispatchSelected(checkedList)
    },

    dispatchSelected(checkedList) {
      this.$emit('selected-linked-entities', checkedList)
    },

    assignRowClass(row, index ) {
     if( this.withActions )
        return ''

      return this.checkedRows.find( r => r.id == row.id ) ? 'is-hidden': ''
    },

    reload(){
      this.emptyFilter()
      this.usersFilter()
    },
  },

};
</script>
