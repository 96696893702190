<template>
    <section>
        <div class="mt-4">
            <div class="flex mr-4 text-blue cursor-pointer" @click="downloadTemplate">
                <b-icon pack="fas" icon="file-excel"></b-icon>
                {{ $t('download_template') }}
            </div>
            <div class="panel">
                <form @submit.prevent="submitForm" :files="true">
                    <div class="flex w-full">
                        <div class="flex">
                            <b-field class="flex mr-4">
                                <b-upload v-model="form.excelFile" @change="cleanErrors" required
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                    <a class="button is-primary">
                                        <b-icon pack="fas" icon="upload"></b-icon>
                                        <span>{{ $t('select_file') }}</span>
                                    </a>
                                </b-upload>
                                <span class="file-name" v-if="form.excelFile">{{ form.excelFile.name }}</span>
                            </b-field>
                        </div>
                        <div class="flex" v-if="entityType == 'assignment'">
                            <b-field class="mr-2">
                                <b-select v-model="form.assignmentType" size="is-small" rounded icon="file"
                                    :required="true">
                                    <option value="task">Tareas</option>
                                    <option value="resources_bc3">Recursos (BC3)</option>
                                    <option value="certifiers">Certificadoras</option>
                                    <option value="resources_bcs">Simple</option>
                                    <!-- para importar mediciones plantilla simple -->
                                    <option value="chapters">Capítulos</option> <!-- para importar capítulos -->
                                    <option value="certifiers-chapters">Capítulos con partidas</option>
                                    <!-- para importar capítulos con certificadoras dentro -->
                                </b-select>
                            </b-field>
                        </div>
                        <div class="flex" v-if="entityType == 'expected_executions'">
                            <b-field class="mr-2">
                                <b-select v-model="form.workType" size="is-small" rounded icon="file" :required="true">
                                    <option value="0">Tuberias</option>
                                    <option value="2">Economico</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="flex" v-if="overwriteEnabled">
                            <v-switch v-model="form.overwrite" ref="form_overwrite" :label="$t('allow_to_overwrite')"
                                :color="form.overwrite ? 'green' : 'red'" class="mr-1"
                                v-on:checked="(value) => form.overwrite = value" />
                        </div>
                        <div class="flex ml-4">
                            <slot name="descripcion"></slot>
                        </div>
                    </div>
                    <!-- fragmento provisional. Lotes canal cargas anteriores -->
                    <div class="flex w-full mt-4 mb-0" v-if="form.assignmentType == 'task'">
                        <div class="flex w-full">
                            (Fecha efectiva: Solo admin Epc-tracker)
                        </div>
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-datepicker icon-pack="fas" type="month" v-model="effectiveDate"
                                    :placeholder="'fecha efectiva'" :month-names="$t('months_names')"
                                    :day-names="$t('days_names')" :first-day-of-week="parseInt($t('first_day_of_week'))"
                                    :readonly="false" :max-date="previousMonth">
                                </b-datepicker>
                            </b-field>
                        </div>
                    </div>
                    <!-- fin fragmento provisional -->
                    <div class="flex w-full mt-4 mb-0"
                        v-if="form.assignmentType == 'certifiers' || form.assignmentType == 'chapters' || form.assignmentType == 'certifiers-chapters'">
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-autocomplete required :placeholder="$t('planning_code') + ' ' + $t('activity')"
                                    v-model="form.activityCode" minlength="3" maxlength="255" expanded
                                    :disabled="disableActivityCode" :data="filteredActivities"
                                    @input="getFilteredActivities">
                                </b-autocomplete>
                            </b-field>
                        </div>
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-datepicker type="month" v-model="effectiveDate" :placeholder="'fecha efectiva'"
                                    :month-names="$t('months_names')" :day-names="$t('days_names')"
                                    :first-day-of-week="parseInt($t('first_day_of_week'))" :readonly="false"
                                    :max-date="previousMonth">
                                </b-datepicker>
                            </b-field>
                        </div>
                    </div>
                    <div class="flex w-full mt-4">
                        <div class="flex w-full">
                            <b-field class="w-full">
                                <b-input required :placeholder="$t('description')" v-model="form.description"
                                    maxlength="255" expanded></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="flex w-full">
                        <v-button :loading="isLoading" type="submit" color="green" icon="file-import" icon-pack="fas">{{
                            $t('import') }}</v-button>
                    </div>
                </form>
            </div>
            <div>
                <b-tabs type="is-boxed" v-model="importActiveTab">
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="box-open"></b-icon>
                            <span>{{ $t('imports_archive') }}</span>
                        </template>
                        <div>
                            <entity-imports-archive v-if="withHistory" :project-id="projectId" :partner-id="partnerId"
                                :company-id="companyId" :entity-type="entityType" :overwrite-field="true"
                                ref="entity_imports"></entity-imports-archive>
                        </div>
                    </b-tab-item>
                    <!-- Agregando nuevo tag que servira para poder ver el excel en una tabla  -->
                    <b-tab-item :disabled="excelTabEnabled">
                        <template slot="header">
                            <b-icon pack="fas" icon="eye"></b-icon>
                            <span> {{ $t('excel_preview') }} </span>
                        </template>
                        <div>
                            <import-excel-preview ref="previewDataExcel" :file="form.excelFile"
                                :overwrite="!!form.overwrite" :projectId="projectId"></import-excel-preview>
                        </div>
                    </b-tab-item>
                </b-tabs>
            </div>
        </div>
    </section>
</template>

<script>
import Form from "vform";
import entityImportsArchive from "./entity-imports-archive";
import ImportExcelPreview from '../components/import-excel-preview'
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
    name: "import-entities",
    components: {
        entityImportsArchive,
        ImportExcelPreview
    },

    props: {
        withHistory: { type: Boolean, default: true },
        projectId: { type: Number, default: null, required: false },
        partnerId: { type: Number, default: null, required: false },
        companyId: { type: Number, default: null, required: false },
        entityId: { type: String, default: '', required: false }, // mediciones certificadoras van sobre una actividad concreta
        entityType: { type: String, required: true },
        canOverwrite: { type: Boolean, default: false },
        actionType: { type: String, default: '' }, // distinciones a tener en cuenta por el importador
        morphType: { type: String, default: '' }, // usado para cuando se tenga que trabajar con importaciones polimorphicas
        assignmentType: { type: String, default: 'certifiers' }, // para cargar certificadoras se requiere que por defecto sea seleccionado
    },

    created() {
        // si pasamos un codigo actividad, para importar cantidades ejecutadas sobre una medicion (desde la modal)
        if (this.entityId !== '') {
            this.form.activityCode = this.entityId
            this.form.overwrite = 1 // para cargar cantidades ejecutadas se requiere esta opcion a true
            this.form.assignmentType = this.assignmentType
            this.disableActivityCode = true
        }
    },

    data: () => ({
        isLoading: false,
        form: new Form({
            projectId: null,
            partnerId: null,
            companyId: null,
            excelFile: null,
            description: "",
            overwrite: false,
            assignmentType: null,
            activityCode: null,
            actionType: "",
            workType: null,
            effectiveDate: "",
        }),
        effectiveDate: null,
        errors: [],
        importActiveTab: 0,
        errorsCount: 0,
        disableActivityCode: false,
        filteredActivities: [],
        excelTabEnabled: true,
    }),

    computed: {
        templateName() {
            // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-')) lo ideal es quitar el replace y mantener una convencion para entitytype
            return this.assetVapor("template_import_" + this.entityType.replace('/', '-') + ".xlsx");
        },
        templateFileName() {
            // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-'))
            return this.$t(this.entityType.replace('/', '-') + "_import_template") + ".xlsx";
        },
        overwriteEnabled() {
            return this.canOverwrite;
        },
        previousMonth() {
            let today = new Date()
            return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        }
    },

    methods: {
        async submitForm() {
            this.importActiveTab = 1;
            this.excelTabEnabled = false;
            this.isLoading = true;
            try {
                let response;
                if (this.$refs.previewDataExcel.previewData.length > 1) {
                    response = await this.$refs.previewDataExcel.sendDataChunks(this.$refs.previewDataExcel.previewData);
                } else {
                    response = await this.$refs.previewDataExcel.processFile();
                }
                this.isLoading = false;

                if (response.success) {
                    this.$notify.success("success_import");
                    const mappedData = this.mapDataToOriginalFormat(this.$refs.previewDataExcel.previewData);
                    this.uploadExcel(mappedData);
                    this.cleanErrors();
                    this.form.excelFile = null;
                } else {
                    this.$notify.error("error_import");
                }
            } catch (error) {
                this.isLoading = false;
                console.error('Error processing file:', error);
                this.$notify.error("error_import");
            }
        },
        mapDataToOriginalFormat(data) {
            return data.map((row) => ({
                'ID grupo': row.group_code || '',
                'ID columna proj': row.id_column || '',
                'ID': row.planning_code || '',
                'ID a actualizar': row.new_planning_code || '',
                'ID actividad madre': row.parent_activity_planning_code || '',
                'Nombre': row.name || '',
                'Planificación': row.in_planning || '',
                'Camino crítico': row.critical_path || '',
                'Fecha inicio programada': row.scheduled_start_date || '',
                'Fecha fin programada': row.scheduled_end_date || '',
                'Fecha inicio actual': row.current_start_date || '',
                'Fecha fin actual': row.current_end_date || '',
                'Predecesoras': row.predecessors || '',
                'Sucesoras': row.successors || '',
                'Progreso': row.progress || '',
                'Ponderación': row.weight || '',
                'Usuario responsable': row.email_manager || '',
                'Referencia de localización': row.location_reference_code || '',
                'Participantes': row.participants || '',
                'Colaboradores': row.collaborators || '',
                'Etiquetas': row.tags || '',
                'Estado': row.status || '',
                'Mostrar en APP': row.show_on_devices || '',
                'Descripción': row.description || '',
                'Documentos': row.document_codes || '',
                'Flujos': row.flow_codes || '',
            }));
        },

        async uploadExcel(data) {
            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Contar la cantidad de filas en el worksheet
            const rowCount = worksheet['!ref'].split(':').pop().match(/\d+/)[0];
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

            const formData = new FormData();
            formData.append('excelFile', blob, 'exported_data.xlsx');
            formData.append('projectId', this.projectId);
            formData.append('withUpdate', this.form.overwrite ? '1' : '0');
            formData.append('rowCount', rowCount);
            formData.append('description', this.form.description);
            try {
                const response = await axios.post('/api/v2/import/activities/optimized/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.$refs.entity_imports.reloadImports();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },

        cleanErrors() {
            this.importActiveTab = 0;
            this.excelTabEnabled = true;
            this.$refs.previewDataExcel.previewData = [];
            this.form.descripcion = '';
        },

        downloadTemplate() {
            let link = document.createElement("a");
            link.href = this.templateName;
            link.download = this.templateFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        async getFilteredActivities() {
            if (this.form.activityCode && this.form.activityCode.length > 2) {
                let getParams = {
                    p: this.projectId,
                    filter_planning_code: this.form.activityCode,
                    light: 1
                }
                const { data } = await axios.get('/api/v2/activities', { params: getParams });
                this.filteredActivities = data.activities.map(activity => activity.planning_code);
            }

            else {
                this.filteredActivities = [];
            }
        },
    }
};
</script>