<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="import_export_activities" />
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span> {{ $t('import_activities') }} </span>
            </template>
            <div>
              <!-- <import-entities :can-overwrite="true" :project-id="project_id" entity-type="activities">
                <template v-slot:descripcion>
                  <span class="text-orange-dark">{{ $t('activity_import_warning') }}</span>
                </template>
              </import-entities> -->
              <!-- TODO: Cambiando al nuevo componente solo para actividades hay que ir haciendo general esa entidad si asi se necesita -->
              <import-entities-activities :can-overwrite="true" :project-id="project_id" entity-type="activities">
                <template v-slot:descripcion>
                  <span class="text-orange-dark">{{ $t('activity_import_warning') }}</span>
                </template>
              </import-entities-activities>
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-export"></b-icon>
              <span> {{ $t('export_activities') }} </span>
            </template>
            <div>
              <export-activities :project_id="project_id"></export-activities>
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="user"></b-icon>
              <span> {{ $t('assign_participants') }} </span>
            </template>
            <div>
              <import-entities :project-id="project_id" entity-type="participants" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="layer-group"></b-icon>
              <span> {{ $t('activity_groups') }} </span>
            </template>
            <div>
              <manage-groups-activities :projectId="project_id" :entityType="'project'"></manage-groups-activities>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ImportEntitiesActivities from '../../components/import-entities-activities.vue'
import ImportEntities from '../../components/import-entities.vue'
import exportActivities from '../../components/export-activities'
import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
import ManageGroupsActivities from '../../components/activity-groups/manage-groups-activities.vue'


export default {
  components: {
    ImportEntitiesActivities,
    exportActivities,
    ProjectAdminQuickButtons,
    ManageGroupsActivities,
    ImportEntities
  },
  middleware: "auth",
  title: "import_export_activities",
  menuOption: "0-2",

  computed: {
    ...mapGetters({
      navData: 'app/navData',
    }),
  },

  data: () => ({
    project_id: null,
    mainActiveTab: 0
  }),

  created() {
    this.$options.subtitle = ['project_actions_page_subtitle', [this.navData.project_name.toUpperCase()]]
    this.project_id = this.navData.project_id
  },

  methods: {

  },
};

</script>
