<template>
  <!-- Cartel de advertencia mala ponderación -->
  <div class="panel">
    <div
      v-if="hasWeightingError"
      class="flex items-center justify-between w-full mb-4"
    >
      <div class="text-lg font-semibold uppercase text-red-light">
        {{ $t("weighting_not_100") }}
      </div>
    </div>
    <!-- Botón de añadir -->
    <div class="flex items-center justify-between w-full mb-4">
      <div class="text-lg font-thin uppercase text-blue-light">
        {{ $t("measurements_system") }}
      </div>

    </div>

    <!-- <div v-if="isLoading" class="my-4 text-center">
      <scale-loader />
    </div>
    <div v-else> -->
      <div class="w-full">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

          <!-- Resource -->
          <b-tab-item v-if="pictogramId == null">

            <template #header>
                  <span> {{ $t("resources") }}
                    <b-icon v-show="isLoadingResource"
                      pack="fas"
                      icon="spinner"
                      class="fa-pulse"
                      size="is-small">
                    </b-icon>
                    <b-tag v-show="!isLoadingResource" rounded class="font-semibold"> {{ listResources.length }} </b-tag> </span>
            </template>

            <div class="w-full">

              <div class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">
                <div>
                  <b-button
                    @click="onClickUnlockMeasurementsButton"
                    @reload="() => reload()"
                    v-if="canManageCertifiers && listResources.length"
                    type="is-small is-primary"
                    class="font-semibold uppercase">
                      <span v-if="assignmentsLocked">{{ $t("unlock_measurements") }}</span>
                      <span v-else>{{ $t("lock_measurements") }}</span>
                  </b-button>
                </div>

                <b-field v-if="listResources.length">
                  <b-tag v-if="assignmentsLocked" type="is-success">
                      <span>{{ $t('blocked') }}</span>
                  </b-tag>
                  <b-tag v-else type="is-warning">
                      <span>{{ $t('unlocked') }}</span>
                  </b-tag>
                </b-field>

                <div>
                  <b-button
                    @click="addResourceButtonClicked"
                    @reload="() => reload()"
                    v-if="canAdd"
                    :disabled="!canEditAssignments()"
                    type="is-small is-primary"
                    class="font-semibold uppercase">{{ $t("add_resource") }}</b-button>
                </div>
              </div>

              <div v-if="isLoadingResource" class="my-4 text-center">
                <scale-loader />
              </div>

              <div v-else class="flex flex-row justify-between mt-3 text-sm uppercase align-middle text-blue-light">
                <div>
                  <span v-if="listResources.length" class="font-semibold">{{ listResources.length }}</span> {{ !listResources.length ? $t("no_resources_found") : $t("resources_under_execution") }}
                </div>
              </div>
            </div>

            <!-- Tabla de Recursos/tareas -->
            <div class="flex items-center my-4" v-if="!isLoadingResource && listResources.length">
              <table class="w-full">
                <thead class="bg-blue-lightest">
                  <tr>
                    <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark"></th>
                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("resource") }}</th>
                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("interval") }}</th>
                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("progress_label") }}</th>
                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="row in listResources">

                    <!-- Recorremos los recursos -->
                    <tr class="hover:bg-grey-lightest" :key="row.id">

                      <!-- Icono Detalles / Historia -->
                      <td class="justify-start w-auto h-full p-4 align-middle">

                        <div v-if="row.lines && row.lines.length" class="flex text-center align-middle cursor-pointer" @click="displayHistory(row.id)">
                          <b-tooltip
                            :label="$t('display_history',[row.lines.length])"
                            position="is-right"
                          >
                            <span class="mr-1 text-xs font-semibold text-blue">{{ row.lines.length }}</span>
                            <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                            <b-icon :icon=" (row.id == selectedHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
                          </b-tooltip>
                        </div>
                        <div v-else class="flex text-center align-middle cursor-pointer" @click="displayDetails(row.id)">
                          <b-tooltip
                            :label="$t('display_details',[getRealChildren(row.children)])"
                            position="is-right"
                            >
                            <span class="mr-1 text-xs font-semibold text-blue">{{ getRealChildren(row.children) }}</span>
                            <b-icon icon="list" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                            <b-icon :icon=" (row.id == selected) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
                          </b-tooltip>
                        </div>
                      </td>

                      <!-- Titulo / Peso -->
                      <td class="p-2" v-if="!row.completed">
                        <div class="text-xs font-semibold">{{ row.name }}</div>
                        <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [row.weight.toFixed(2)]) }}</span></div>

                        <div v-if="row.order">
                          <span class="text-xs">Sub.:</span> <span class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{ getNameOrCodePartner(row.order.partners) }}</span>
                        </div>

                      </td>

                      <td class="p-2" v-else>
                        <div class="flex flex-row items-center">
                          <div class="w-2 h-2 mr-1 rounded-full bg-green"></div>
                          <div class="text-xs font-semibold">{{ row.name }}</div>
                        </div>
                        <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [row.weight.toFixed(2)]) }}</span></div>
                      </td>

                      <!-- Fechas / Plazo-->
                      <td class="p-2">
                        <div class="flex flex-col" v-if="row.start_date != null">
                          <div class="text-xs">{{ $t('resource_date_interval', [moment(row.start_date).format('DD/MM/YYYY'), moment(row.end_date).format('DD/MM/YYYY')]) }}</div>
                          <div class="flex flex-row items-center" v-if="row.end_date > realEndDate">
                            <div class="w-2 h-2 rounded-full bg-red"></div>
                            <div class="ml-1 text-xs font-medium text-red">Fuera de plazo</div>
                          </div>
                          <div class="flex flex-row items-center" v-else>
                            <div class="w-2 h-2 rounded-full bg-green"></div>
                            <div class="ml-1 text-xs font-medium text-green">En plazo</div>
                          </div>
                        </div>
                        <div class="flex flex-col" v-else>
                          <div class="text-xs" >Sin iniciar</div>
                          <div class="flex flex-row items-center">
                            <div class="w-2 h-2 rounded-full bg-grey"></div>
                            <div class="ml-1 text-xs font-medium text-grey">Pendiente de ejecutar</div>
                          </div>
                        </div>
                      </td>

                      <!-- Pendiente / Ejecutado -->
                      <td class="p-2">
                        <div class="flex flex-col">
                          <div class="text-xs text-grey-dark">{{ ((row.total_quantity - row.executed_quantity) == 0) ? (row.total_quantity - row.executed_quantity).toFixed() : (row.total_quantity - row.executed_quantity).toFixed(2) }} {{ row.unit.code }} {{ $t('pendings') }}</div>
                          <div class="text-xs font-medium text-blue">{{ (row.executed_quantity == 0) ? '0' : row.executed_quantity.toFixed(2) }} / {{ row.total_quantity }} {{ row.unit.code }} {{ $t('executed') }}</div>
                        </div>
                      </td>

                      <!-- Opciones -->
                      <td class="justify-center w-auto h-full p-4 align-middle">
                        <div class="text-center cursor-pointer">
                          <b-dropdown aria-role="list" position="is-bottom-left">

                            <template #trigger="{ active }">
                                <b-icon :icon="active ? 'ellipsis-h' : 'ellipsis-v'" pack="fas" size="is-small" class="text-blue"></b-icon>
                            </template>
                            <!-- <b-dropdown-item aria-role="listitem" @click="onNameClick(row)" v-if="row.children.length == 0 && !row.completed" >
                              <span class="text-xs">{{ $t('register_progress') }}</span>
                            </b-dropdown-item> -->
                            <b-dropdown-item aria-role="listitem" @click="onNameNewClick(row)" v-if="row.children.length == 0 && !row.completed" >
                              <span class="text-xs">{{ $t('register_progress') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.lines == null" aria-role="listitem" @click="addSubResource(row)" :disabled="!canEditAssignments()">
                              <span class="text-xs">{{ $t('add_subresource') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="!row.completed" aria-role="listitem" @click="onClickEdit(row)" :disabled="!canEditAssignments()">
                              <span class="text-xs">{{ $t('edit') }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item separator v-if="!row.completed && !row.children.length > 0"></b-dropdown-item>
                            <b-dropdown-item aria-role="listitem" @click="onCompleteChanged(row)" v-if="row.children.length == 0" :class="user.collaborator == 1 ? 'cursor-not-allowed':''">
                              <span v-if="!row.completed" class="text-xs font-medium" :class="user.collaborator == 1 ? 'text-gray-400':'text-green'">{{ $t('complete_resource') }}</span>
                              <span v-else class="text-xs font-medium text-red">{{ $t('uncomplete') }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </td>

                    </tr>

                    <!-- Recorremos los sub recursos -->
                    <tr v-if="row.children.length" :key="'sub_'+row.id">

                      <td colspan="5" :class="{'hidden': !(row.id == selected)}" >

                        <table class="w-full">

                          <!-- Cabecera Separadora -->
                          <tr class="bg-indigo-lightest">
                            <td colspan="5" class="p-4">
                              <div class="flex justify-start w-auto h-full align-middle">
                                <b-icon icon="list" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                <span class="ml-2 text-xs font-semibold uppercase text-blue">{{ getRealChildren(row.children)}} {{ $t("resources") }}</span>
                              </div>
                            </td>
                          </tr>

                          <!-- Cabeceras de tabla -->
                          <tr class="bg-blue-lightest">
                            <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark"></th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("resource") }}</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("interval") }}</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("progress_label") }}</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
                          </tr>

                          <!-- Subrecursos -->
                          <tbody>

                            <template v-for="rowSub in row.children">

                              <tr class=" bg-grey-lighter hover:bg-grey-light" v-if="!rowSub.is_header_row" :key="rowSub.id">

                                <!-- Icono Historial -->
                                <td class="justify-start w-auto h-full p-4 align-middle">
                                  <div v-if="rowSub.lines" class="flex text-center align-middle cursor-pointer" @click="displaySubHistory(rowSub.id)">
                                    <span class="mr-1 text-xs font-semibold text-blue">{{ rowSub.lines.length }}</span>
                                    <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                    <b-icon :icon=" (rowSub.id == selectedSubHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
                                  </div>
                                </td>

                                <!-- Titulo / Peso -->
                                <td class="p-2" v-if="!rowSub.completed">
                                  <div class="text-xs font-semibold">{{ rowSub.name }}</div>
                                  <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [rowSub.weight.toFixed(2)]) }}</span></div>
                                </td>
                                <td class="p-2" v-else>
                                  <div class="flex flex-row items-center">
                                    <div class="w-2 h-2 mr-1 rounded-full bg-green"></div>
                                    <div class="text-xs font-semibold">{{ rowSub.name }}</div>
                                  </div>
                                  <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [rowSub.weight.toFixed(2)]) }}</span></div>
                                </td>

                                <!-- Fechas / Plazo-->
                                <td class="p-2">
                                  <div class="flex flex-col" v-if="rowSub.start_date != null">
                                    <div class="text-xs">{{ $t('resource_date_interval', [moment(rowSub.start_date).format('DD/MM/YYYY'), moment(rowSub.end_date).format('DD/MM/YYYY')]) }}</div>
                                    <div class="flex flex-row items-center" v-if="rowSub.end_date > realEndDate">
                                      <div class="w-2 h-2 rounded-full bg-red"></div>
                                      <div class="ml-1 text-xs font-medium text-red">Fuera de plazo</div>
                                    </div>
                                    <div class="flex flex-row items-center" v-else>
                                      <div class="w-2 h-2 rounded-full bg-green"></div>
                                      <div class="ml-1 text-xs font-medium text-green">En plazo</div>
                                    </div>
                                  </div>
                                  <div class="flex flex-col" v-else>
                                    <div class="text-xs" >Sin iniciar</div>
                                    <div class="flex flex-row items-center">
                                      <div class="w-2 h-2 rounded-full bg-grey"></div>
                                      <div class="ml-1 text-xs font-medium text-grey">Pendiente de ejecutar</div>
                                    </div>
                                  </div>
                                </td>

                                <!-- Pendiente / Ejecutado -->
                                <td class="p-2">
                                  <div class="flex flex-col">
                                    <div class="text-xs text-grey-dark">{{ ((rowSub.total - rowSub.executed_quantity) == 0) ? (rowSub.total - rowSub.executed_quantity).toFixed() : (rowSub.total - rowSub.executed_quantity).toFixed(2) }} {{ rowSub.unit.code }} {{ $t('pendings') }}</div>
                                    <div class="text-xs font-medium text-blue">{{ (rowSub.executed_quantity == 0) ? '0' : rowSub.executed_quantity.toFixed(2) }} / {{ rowSub.total }} {{ rowSub.unit.code }} {{ $t('executed') }}</div>
                                  </div>
                                </td>

                                <!-- Opciones -->
                                <td class="justify-center w-auto h-full p-4 align-middle">
                                  <div class="text-center cursor-pointer">
                                    <b-dropdown aria-role="list" position="is-bottom-left">
                                      <template #trigger="{ active }">
                                          <b-icon :icon="active ? 'ellipsis-h' : 'ellipsis-v'" pack="fas" size="is-small" class="text-blue"></b-icon>
                                      </template>
                                      <b-dropdown-item aria-role="listitem" @click="onNameClick(rowSub)" v-if="!rowSub.completed">
                                        <span class="text-xs">{{ $t('register_progress') }}</span>
                                      </b-dropdown-item>
                                      <b-dropdown-item aria-role="listitem" @click="onClickEdit(rowSub)" v-if="!rowSub.completed" :disabled="!canEditAssignments()">
                                        <span class="text-xs">{{ $t('edit') }}</span>
                                      </b-dropdown-item>
                                      <b-dropdown-item separator v-if="!rowSub.completed"></b-dropdown-item>
                                      <b-dropdown-item aria-role="listitem" @click="onCompleteChanged(rowSub)" :class="user.collaborator == 1 ? 'cursor-not-allowed':''">
                                          <span v-if="!rowSub.completed" class="text-xs font-medium" :class="user.collaborator == 1 ? 'text-gray-400':'text-green'">{{ $t('complete_resource') }}</span>
                                          <span v-else class="text-xs font-medium text-red">{{ $t('uncomplete') }}</span>
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </div>
                                </td>

                              </tr>

                              <tr :key="'subhistory_'+rowSub.id">
                                <td colspan="5" :class="{'hidden': !(rowSub.id == selectedSubHistory)}" >
                                  <table class="w-full">

                                      <tr class="bg-indigo-lightest">

                                      <td colspan="5" class="p-4">
                                          <div class="flex justify-start w-auto h-full align-middle">
                                          <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                          <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                                          </div>
                                      </td>
                                      </tr>

                                      <tr class="bg-blue-lightest">
                                          <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                                          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                                          <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                                          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                                          <th>&nbsp;</th>
                                      </tr>

                                      <tbody>
                                          <template v-for="rowSubHistory in rowSub.lines">
                                          <tr :key="rowSubHistory.id" class="hover:bg-grey-lightest">

                                              <td align="center" class="justify-start w-auto h-full p-4">
                                                  <div class="text-xs text-center">
                                                  <!--
                                                    <l-timeago
                                                      :since="rowSubHistory.created_at"
                                                      :locale="locale"
                                                      class="my-1 text-xs font-semibold text-blue-light"
                                                  />
                                                  -->
                                                    <div class="my-1 text-xs font-semibold text-blue-light">
                                                      {{dateToText(rowSubHistory.created_at)}}
                                                    </div>
                                                  </div>
                                              </td>

                                              <td class="p-2">

                                                  <div class="flex flex-row items-center justify-start w-auto h-full">
                                                  <div class="w-8 h-8 rounded-full">
                                                      <img class="w-8 h-8 rounded-full" :src="rowSubHistory.user.avatar"/>
                                                  </div>
                                                  <div class="flex flex-col ml-2">
                                                      <div class="text-xs">{{ rowSubHistory.user.fullname}}</div>
                                                      <div class="text-xs text-grey-dark">{{ rowSubHistory.user.area}}</div>
                                                  </div>
                                                  </div>

                                              </td>

                                              <td align="center" class="h-full p-4">
                                                  <div class="text-xs">
                                                    <span v-if="rowSubHistory.completed == 1 && rowSubHistory.executed_quantity == 0">
                                                      completó la medición
                                                    </span>
                                                    <span v-else>
                                                      <span v-if="rowSubHistory.status == 0">{{ $t('execute') }}</span>
                                                      <span v-if="rowSubHistory.status == 1">pendiente confirmar</span>
                                                      <span v-if="rowSubHistory.status == 2">ejecución rechazada</span>
                                                      <span v-if="rowSubHistory.status == 3">validó</span>
                                                      <span v-if="rowSubHistory.status == 4">rechazó</span>
                                                      {{ rowSubHistory.executed_quantity}} {{ rowSubHistory.unit.name}}
                                                    </span>
                                                  </div>
                                              </td>

                                              <td class="w-2/5 p-2">
                                                  <div class="flex flex-col">
                                                  <div class="text-xs" v-html="rowSubHistory.observation"></div>
                                                  <div class="flex flex-row">
                                                    <template v-for="rowDocument in rowSubHistory.documents">
                                                      <b-tooltip :label="rowDocument.name" position="is-top" :key="rowDocument.document_id">
                                                        <div @click="showPreview(rowDocument)" :key="rowDocument.document_id" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                                          <img class="w-12 h-12 rounded-lg" :src="rowDocument.file.raw" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'" />
                                                        </div>
                                                      </b-tooltip>
                                                    </template>
                                                  </div>
                                                  </div>
                                              </td>

                                              <td class="justify-center w-auto h-full p-1 align-middle">
                                                <div v-if="canManage && rowSubHistory.status == 0" @click="annulAction(rowSubHistory, rowSub.id)">
                                                  <b-tooltip :label="'Anular acción'" position="is-left" :key="'sub_annul_'+rowSubHistory.id">
                                                    <b-icon icon="trash-alt" pack="fas" size="is-small" class="ml-1 text-blue" ></b-icon>
                                                  </b-tooltip>
                                                </div>
                                              </td>

                                          </tr>
                                          </template>
                                      </tbody>

                                  </table>
                              </td>
                            </tr>

                            </template>

                          </tbody>

                        </table>

                      </td>

                    </tr>

                    <tr v-else  :key="'resource_history_'+row.id">
                      <td colspan="5" :class="{'hidden': !(row.id == selectedHistory)}" >
                          <table class="w-full">

                              <tr class="bg-indigo-lightest">
                                  <td colspan="5" class="p-4">
                                      <div class="flex justify-start w-auto h-full align-middle">
                                      <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                      <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                                      </div>
                                  </td>
                              </tr>

                              <tr class="bg-blue-lightest">
                                  <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                                  <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                                  <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                                  <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                                  <th>&nbsp;</th>
                              </tr>

                              <tbody>
                                  <template v-for="rowHistory in row.lines">
                                  <tr :key="'history_line_'+rowHistory.id" class="hover:bg-grey-lightest">

                                      <td align="center" class="justify-start w-auto h-full p-4">
                                          <div class="text-xs text-center">
                                          <!-- <l-timeago
                                              :since="rowHistory.created_at"
                                              :locale="locale"
                                              class="my-1 text-xs font-semibold text-blue-light"
                                          />
                                          -->
                                            <div class="my-1 text-xs font-semibold text-blue-light">
                                              {{dateToText(rowHistory.created_at)}}
                                            </div>
                                          </div>
                                      </td>

                                      <td class="p-2">

                                          <div class="flex flex-row items-center justify-start w-auto h-full">
                                          <div class="w-8 h-8 rounded-full">
                                              <img class="w-8 h-8 rounded-full" :src="rowHistory.user.avatar"/>
                                          </div>
                                          <div class="flex flex-col ml-2">
                                              <div class="text-xs">{{ rowHistory.user.fullname}}</div>
                                              <div class="text-xs text-grey-dark">{{ rowHistory.user.area}}</div>
                                          </div>
                                          </div>

                                      </td>

                                      <td align="center" class="h-full p-4">
                                              <div class="text-xs">
                                                <span v-if="rowHistory.completed == 1 && rowHistory.executed_quantity == 0">
                                                  completó la medición
                                                </span>
                                                <span v-else>
                                                  <span v-if="rowHistory.status == 0">{{ $t('execute') }}</span>
                                                  <span v-if="rowHistory.status == 1">pendiente confirmar</span>
                                                  <span v-if="rowHistory.status == 2">ejecución rechazada</span>
                                                  <span v-if="rowHistory.status == 3">validó</span>
                                                  <span v-if="rowHistory.status == 4">rechazó</span>
                                                  {{ rowHistory.executed_quantity}} {{ rowHistory.unit.name}}
                                                  <br> <span>{{ $t('resource_date_interval', [moment(rowHistory.start_date).format('DD/MM/YYYY'), moment(rowHistory.end_date).format('DD/MM/YYYY')]) }}
                                                  </span>
                                                </span>
                                              </div>
                                      </td>

                                      <td class="w-2/5 p-2">
                                          <div class="flex flex-col">
                                          <div class="text-xs" v-html="rowHistory.observation"></div>
                                          <div class="flex flex-row">

                                            <template v-for="rowDocument in rowHistory.documents">
                                            <b-tooltip :label="rowDocument.name" position="is-top" :key="rowDocument.document_id">
                                              <div @click="showPreview(rowDocument)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                                <img class="w-12 h-12 rounded-lg" :src="rowDocument.file.thumbnail" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'" />
                                              </div>
                                            </b-tooltip>

                                            </template>
                                          </div>
                                          </div>
                                      </td>

                                      <td class="justify-center w-auto h-full p-1 align-middle">
                                        <div v-if="canManage && rowHistory.status == 0" @click="annulAction(rowHistory, row.id)">
                                          <b-tooltip :label="'Anular acción'" position="is-left" :key="'annul_'+rowHistory.id">
                                            <b-icon icon="trash-alt" pack="fas" size="is-small" class="ml-1 text-blue" ></b-icon>
                                          </b-tooltip>
                                        </div>
                                      </td>

                                  </tr>
                                  </template>
                              </tbody>

                          </table>
                      </td>
                    </tr>

                  </template>
                </tbody>
              </table>
            </div>

          </b-tab-item>

          <!-- Task -->
          <b-tab-item v-if="pictogramId == null">

            <template #header>
              <span> {{ $t('tasks') }}
                <b-icon v-show="isLoadingTask"
                  pack="fas"
                  icon="spinner"
                  class="fa-pulse"
                  size="is-small">
                </b-icon>
                <b-tag v-show="!isLoadingTask" rounded class="font-semibold"> {{ listTasks.length }} </b-tag>
              </span>
            </template>

            <div class="w-full">

              <div class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">
                <div></div>
                <div></div>
                <div>
                  <b-button
                    @click="addTaskButtonClicked"
                    @reload="() => reload()"
                    v-if="canAdd"
                    type="is-small is-primary"
                    class="font-semibold uppercase">{{ $t("add_task") }}</b-button>
                </div>
              </div>

              <div v-if="isLoadingTask" class="my-4 text-center">
                <scale-loader />
              </div>

              <div v-else class="flex flex-row justify-between mt-3 text-sm uppercase align-middle text-blue-light">
                <div>
                  <span v-if="listTasks.length" class="font-semibold">{{ listTasks.length }}</span> {{ !listTasks.length ? $t("no_tasks_found") : $t("tasks_under_execution") }}
                </div>
              </div> <!-- creo que sobra -->

              <div class="flex items-center my-4" v-if="!isLoadingTask && listTasks.length">

                  <table class="w-full">

                    <thead class="bg-blue-lightest">
                      <tr>
                        <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark"></th>
                        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("task") }}</th>
                        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("description") }}</th>
                        <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark">{{ $t("status") }}</th>
                        <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="row in listTasks">

                        <!-- Recorremos los recursos -->
                        <tr class="hover:bg-grey-lightest" :key="row.id">

                          <!-- Icono Detalles / Historia -->
                          <td class="justify-start w-auto h-full p-4 align-middle">

                            <div v-if="row.lines && row.lines.length" class="flex text-center align-middle cursor-pointer" @click="displayHistory(row.id)">
                              <b-tooltip
                                :label="$t('display_history',[row.lines.length])"
                                position="is-right"
                              >
                                <span class="mr-1 text-xs font-semibold text-blue">{{ row.lines.length }}</span>
                                <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                <b-icon :icon=" (row.id == selectedHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
                              </b-tooltip>
                            </div>

                          </td>

                          <!-- Titulo / Peso -->
                          <td class="p-2" v-if="!row.completed">
                            <div class="text-xs font-semibold">{{ row.name }}</div>
                            <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [row.weight.toFixed(2)]) }}</span></div>
                          </td>
                          <td class="p-2" v-else>
                            <div class="flex flex-row items-center">
                              <div class="w-2 h-2 mr-1 rounded-full bg-green"></div>
                              <div class="text-xs font-semibold">{{ row.name }}</div>
                            </div>
                            <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [row.weight.toFixed(2)]) }}</span></div>
                          </td>

                          <!-- Fechas / Plazo-->
                          <td class="p-2">
                            <div class="flex flex-col" v-if="row.start_date != null">
                              <div class="text-xs">{{ $t('resource_date_interval', [moment(row.start_date).format('DD/MM/YYYY'), moment(row.end_date).format('DD/MM/YYYY')]) }}</div>
                              <div class="flex flex-row items-center" v-if="row.end_date > realEndDate">
                                <div class="w-2 h-2 rounded-full bg-red"></div>
                                <div class="ml-1 text-xs font-medium text-red">Fuera de plazo</div>
                              </div>
                              <div class="flex flex-row items-center" v-else>
                                <div class="w-2 h-2 rounded-full bg-green"></div>
                                <div class="ml-1 text-xs font-medium text-green">En plazo</div>
                              </div>
                            </div>
                            <div class="flex flex-col" v-else>
                              <div class="text-xs" >Sin iniciar</div>
                              <div class="flex flex-row items-center">
                                <div class="w-2 h-2 rounded-full bg-grey"></div>
                                <div class="ml-1 text-xs font-medium text-grey">Pendiente de ejecutar</div>
                              </div>
                            </div>
                          </td>

                          <!-- Pendiente / Ejecutado -->
                          <td class="p-4">
                            <div class="flex justify-start w-auto h-full align-middle" v-if="row.completed">
                              <div class="px-2 py-1 text-xs text-white rounded-full bg-green">Completada</div>
                            </div>
                          </td>

                          <!-- Opciones -->
                          <td class="justify-center w-auto h-full p-4 align-middle">
                            <div class="text-center cursor-pointer">
                              <b-dropdown aria-role="list" position="is-bottom-left">

                                <template #trigger="{ active }">
                                    <b-icon :icon="active ? 'ellipsis-h' : 'ellipsis-v'" pack="fas" size="is-small" class="text-blue"></b-icon>
                                </template>
                                <b-dropdown-item aria-role="listitem" @click="onClickTaskEdit(row)" v-if="!row.completed">
                                  <span class="text-xs">{{ $t('edit') }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item separator v-if="!row.completed"></b-dropdown-item>
                                <b-dropdown-item aria-role="listitem" @click="onCompleteChanged(row)" v-if="row.children.length == 0" :class="user.collaborator == 1 ? 'cursor-not-allowed':''">
                                  <span v-if="!row.completed" class="text-xs font-medium" :class="user.collaborator == 1 ? 'text-gray-400':'text-green'">{{ $t('complete_resource') }}</span>
                                  <span v-else class="text-xs font-medium text-red">{{ $t('uncomplete') }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </td>

                        </tr>

                        <tr  :key="'task_history_'+row.id">
                          <td colspan="5" :class="{'hidden': !(row.id == selectedHistory)}" >
                              <table class="w-full">

                                  <tr class="bg-indigo-lightest">

                                      <td colspan="4" class="p-4">
                                              <div class="flex justify-start w-auto h-full align-middle">
                                              <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                              <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                                              </div>
                                          </td>
                                          </tr>

                                          <tr class="bg-blue-lightest">
                                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                                          </tr>

                                  <tbody>
                                      <template v-for="rowHistory in row.lines">
                                      <tr :key="rowHistory.id" class="hover:bg-grey-lightest">

                                          <td align="center" class="justify-start w-auto h-full p-4">
                                              <div class="text-xs text-center">
                                              <!--
                                              <l-timeago
                                                  :since="rowHistory.created_at"
                                                  :locale="locale"
                                                  class="my-1 text-xs font-semibold text-blue-light"
                                              />
                                              -->
                                                <div class="my-1 text-xs font-semibold text-blue-light">
                                                  {{dateToText(rowHistory.created_at)}}
                                                </div>
                                              </div>
                                          </td>

                                          <td class="p-2">

                                              <div class="flex flex-row items-center justify-start w-auto h-full">
                                              <div class="w-8 h-8 rounded-full">
                                                  <img class="w-8 h-8 rounded-full" :src="rowHistory.user.avatar"/>
                                              </div>
                                              <div class="flex flex-col ml-2">
                                                  <div class="text-xs">{{ rowHistory.user.fullname}}</div>
                                                  <div class="text-xs text-grey-dark">{{ rowHistory.user.area}}</div>
                                              </div>
                                              </div>

                                          </td>

                                          <td align="center" class="h-full p-4">
                                                  <div class="text-xs">{{ $t('execute') }} {{ rowHistory.executed_quantity}} {{ rowHistory.unit.name }}</div>
                                          </td>

                                          <td class="w-2/5 p-2">
                                              <div class="flex flex-col">
                                              <div class="text-xs" v-html="rowHistory.observation"></div>
                                              <div class="flex flex-row">

                                                <template v-for="rowDocument in rowHistory.documents">
                                                  <b-tooltip :label="rowDocument.name" position="is-top" :key="rowDocument.document_id">
                                                    <div @click="showPreview(rowDocument)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                                      <img class="w-12 h-12 rounded-lg" :src="rowDocument.file.thumbnail" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'" />
                                                    </div>
                                                  </b-tooltip>
                                                </template>
                                              </div>
                                              </div>
                                          </td>

                                      </tr>
                                      </template>
                                  </tbody>

                              </table>
                          </td>
                        </tr>

                      </template>
                    </tbody>
                </table>

              </div>
            </div>

          </b-tab-item>

          <!-- Certifiers -->
          <b-tab-item v-if="pictogramId == null">
            <template #header>
              <span> {{ $t('certifiers') }}
                <b-icon v-show="isLoadingCertifier"
                  pack="fas"
                  icon="spinner"
                  class="fa-pulse"
                  size="is-small">
                </b-icon>
                <b-tag v-show="!isLoadingCertifier" rounded class="font-semibold"> {{ listCertifiers.length }} </b-tag>
              </span>
            </template>

            <div class="w-full">
              <div class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">
                <div>
                  <b-button
                    @click="() => { assignmentType = 'certifiers'; $refs.assignment_certified_execution_modal.show() }"
                    @reload="() => reload()"
                    v-if="canManageCertifiers"
                    type="is-small is-primary"
                    class="font-semibold uppercase">{{ $t("upload_certification") }}
                  </b-button>
                </div>

                <div>
                  <b-button
                    @click="onClickUnlockMeasurementsButton"
                    @reload="() => reload()"
                    v-if="canManageCertifiers"
                    type="is-small is-primary"
                    class="font-semibold uppercase">
                      <span v-if="assignmentsLocked">{{ $t("unlock_measurements") }}</span>
                      <span v-else>{{ $t("lock_measurements") }}</span>
                  </b-button>
                </div>

                <b-field>
                  <b-tag v-if="assignmentsLocked" type="is-success">
                      <span>{{ $t('blocked') }}</span>
                  </b-tag>
                  <b-tag v-else type="is-warning">
                      <span>{{ $t('unlocked') }}</span>
                  </b-tag>
                </b-field>
              </div>

              <div v-if="isLoadingCertifier" class="my-4 text-center">
                <scale-loader />
              </div>

              <div v-else class="flex flex-row justify-between mt-3 text-sm uppercase align-middle text-blue-light">

                <div>
                  <span v-if="listCertifiers.length" class="font-semibold">{{ listCertifiers.length }}</span> {{ !listCertifiers.length ? $t("no_certifiers_found") : $t("certifiers_under_execution") }}
                </div>

              </div> <!-- creo que sobra -->

              <!-- Tabla de Certificadoras -->
              <div class="flex items-center my-4" v-if="!isLoadingCertifier && listCertifiers.length">

                  <table class="w-full">

                    <thead class="bg-blue-lightest">
                      <tr>
                        <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark"></th>
                        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("name") }}</th>
                        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("progress") }}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="row in listCertifiers">

                        <!-- Recorremos las certificadoras -->
                        <tr class="hover:bg-grey-lightest" :key="row.id">

                          <!-- Icono Detalles / Historia -->
                          <td class="justify-start w-auto h-full p-4 align-middle">

                            <div class="flex text-center align-middle cursor-pointer" @click="displayHistory(row.id)">
                              <b-tooltip
                                :label="$t('display_history',[(row.lines ? row.lines.length : 0)])"
                                position="is-right"
                              >
                                <span class="mr-1 text-xs font-semibold text-blue">{{ row.lines ? row.lines.length : 0 }}</span>
                                <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                <b-icon :icon=" (row.id == selectedHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
                              </b-tooltip>
                            </div>
                          </td>

                          <!-- Nombre -->
                          <td class="p-2" v-if="!row.completed">
                            <div class="text-xs font-semibold">{{ row.name }}</div>
                          </td>
                          <td class="p-2" v-else>
                            <div class="flex flex-row items-center">
                              <div class="w-2 h-2 mr-1 rounded-full bg-green"></div>
                              <div class="text-xs font-semibold">{{ row.name }}</div>
                            </div>
                          </td>
                          <!-- Total unit -->
                          <!-- <td class="p-2">
                            <div class="flex flex-col">
                              <div class="text-xs">{{ row.total_units }} / {{ row.unit.code }}</div>
                            </div>
                          </td> -->
                          <!-- Pendiente / Ejecutado -->
                          <td class="p-2">
                            <div class="flex flex-col">
                              <div class="text-xs">{{ row.total_units }} {{ row.unit.code }} {{ $t("total").toLowerCase() }}</div>
                              <div class="text-xs text-grey-dark">{{ calculateCertifierValues(row, 'pending') }} {{ row.unit.code }} {{ $t('pendings') }}</div>
                              <div class="text-xs font-medium text-blue">{{ calculateCertifierValues(row, 'assigned') }} {{ row.unit.code }} {{ $t('certified').toLowerCase() }}</div>
                            </div>
                          </td>
                        </tr>

                        <tr  :key="'certifier_history_'+row.id">
                          <td colspan="5" :class="{'hidden': !(row.id == selectedHistory)}" >
                              <table class="w-full">

                                  <tr class="bg-indigo-lightest">

                                      <td colspan="4" class="p-4">
                                              <div class="flex justify-start w-auto h-full align-middle">
                                              <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                              <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                                              </div>
                                          </td>
                                          </tr>

                                          <tr class="bg-blue-lightest">
                                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                                          </tr>

                                  <tbody>
                                      <template v-for="rowHistory in row.lines">
                                      <tr :key="rowHistory.id" class="hover:bg-grey-lightest">

                                          <td align="center" class="justify-start w-auto h-full p-4">
                                              <div class="text-xs text-center">
                                                <!--
                                                <l-timeago
                                                    :since="rowHistory.created_at"
                                                    :locale="locale"
                                                    class="my-1 text-xs font-semibold text-blue-light"
                                                />
                                                -->
                                                <div class="my-1 text-xs font-semibold text-blue-light">
                                                  {{dateToText(rowHistory.created_at)}}
                                                </div>
                                              </div>
                                          </td>

                                          <td class="p-2">

                                              <div class="flex flex-row items-center justify-start w-auto h-full">
                                              <div class="w-8 h-8 rounded-full">
                                                  <img class="w-8 h-8 rounded-full" :src="rowHistory.user.avatar"/>
                                              </div>
                                              <div class="flex flex-col ml-2">
                                                  <div class="text-xs">{{ rowHistory.user.fullname}}</div>
                                                  <div class="text-xs text-grey-dark">{{ rowHistory.user.area}}</div>
                                              </div>
                                              </div>

                                          </td>

                                          <td align="center" class="h-full p-4">
                                                  <div class="text-xs">{{ $t('execute') }} {{ rowHistory.executed_quantity}} {{ rowHistory.unit.name }}</div>
                                          </td>

                                          <td class="w-2/5 p-2">
                                              <div class="flex flex-col">
                                              <div class="text-xs" v-html="rowHistory.observation"></div>
                                              <div class="flex flex-row">

                                                <template v-for="rowDocument in rowHistory.documents">
                                                  <b-tooltip :label="rowDocument.name" position="is-top" :key="rowDocument.document_id">
                                                    <div @click="showPreview(rowDocument)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                                      <img class="w-12 h-12 rounded-lg" :src="rowDocument.file.thumbnail" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'" />
                                                    </div>
                                                  </b-tooltip>
                                                </template>
                                              </div>
                                              </div>
                                          </td>

                                      </tr>
                                      </template>
                                  </tbody>

                              </table>
                          </td>
                        </tr>

                      </template>
                    </tbody>

                </table>

              </div> <!-- table certifiers -->

            </div> <!-- w-full -->

          </b-tab-item>

          <!-- Chapters -->
          <b-tab-item v-if="pictogramId == null">
            <template #header>
              <span> {{ $t('certifiers') + ' ' + $t('chapters') }}
                <b-icon v-show="isLoadingCertifierHead"
                  pack="fas"
                  icon="spinner"
                  class="fa-pulse"
                  size="is-small">
                </b-icon>
                <b-tag v-show="!isLoadingCertifierHead" rounded class="font-semibold"> {{ chaptersCount }} </b-tag>
              </span>
            </template>

            <div class="w-full">
              <!-- tabla de capitulos -->
              <chapter-table-activity
                :listChapters="listChapters"
                :chaptersCount="chaptersCount"
                :canManageCertifiers="canManageCertifiers"
                :assignmentsLocked="!assignmentsLocked || (assignmentsLocked && canManageCertifiers)"
                @show-execution-modal="() => {  assignmentType = 'chapters'; $refs.assignment_certified_execution_modal.show() }"
                @unlock-measurements="onClickUnlockMeasurementsButton"
                :is-loading="isLoadingCertifierHead"
              />
            </div>
          </b-tab-item>

          <!-- Pictograms -->
          <b-tab-item v-if="pictogramId != null">
            <template #header>
              <span> {{ $t('pictograms') }}
                <b-icon v-show="isLoadingPictogram"
                  pack="fas"
                  icon="spinner"
                  class="fa-pulse"
                  size="is-small">
                </b-icon>
                <b-tag v-show="!isLoadingPictogram" rounded class="font-semibold"> {{ pictogram ? 1 : 0 }} </b-tag>
              </span>
            </template>

            <div class="w-full">
              <!-- tabla de pictogramas -->
              <pictogram-table-activity
                :pictogram="pictogram"
                :canManageCertifiers="canManageCertifiers"
                :assignmentsLocked="!assignmentsLocked || (assignmentsLocked && canManageCertifiers)"
                :is-loading="isLoadingPictogram"
                :activityId="activityId"
                :subactivityId="subactivityId"
                @show-execution-modal="() => {  assignmentType = 'chapters'; $refs.assignment_certified_execution_modal.show() }"
                @unlock-measurements="onClickUnlockMeasurementsButton"
                @epc-reload="() => reload()"
              />
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    <!-- </div> -->
    <!-- Modal para crear tarea -->
    <task-modal
      ref="task_modal"
      entity-type="activity"
      :entity-id="entityId"
      @reload="() => reload()"
    />

    <!-- Modal para crear recursos -->
    <assignment-modal
      ref="assignment_modal"
      entity-type="activity"
      :entity-id="entityId"
      @reload="() => reload()"
    />

    <!-- Modal para registrar cantidades ejecutadas de mediciones certificadoras -->
    <assignment-certified-execution-modal
      ref="assignment_certified_execution_modal"
      entity-type="activity"
      :entity-id="entityId"
      :entity-planning-code="activityPlanningCode"
      :assignmentType="assignmentType"
      @reload="() => reload()"
    />

    <!-- Modal para Editar tarea -->
    <task-update-modal
      ref="task_update_modal"
      entity-type="activity"
      :entity-id="entityId"
      :canManage="canManage"
      @reload="() => reload()"
    />

    <!-- Modal para Editar -->
    <assignment-update-modal
      ref="assignment_update_modal"
      entity-type="activity"
      :entity-id="entityId"
      :canManage="canManage"
      @reload="() => reload()"
    />

    <!-- Modal para añadir unidades -->
    <!-- <assignment-contribute-modal
      ref="assignment_contribute_modal"
      entity-type="activity"
      :entity-id="entityId"
      :current-start-date="currentStartDate"
      :current-end-date="currentEndDate"
      @reload="() => reload()"
    /> -->

    <assignment-contribute-drawing-modal
      ref="assignment_contribute_drawing_modal"
      entity-type="activity"
      :entity-id="entityId"
      :current-start-date="currentStartDate"
      :current-end-date="currentEndDate"
      @reload="() => reload()"
    />

    <!-- Modal para completar -->
    <assignment-completed-modal
      ref="assignment_completed_modal"
      entity-type="activity"
      :entity-id="entityId"
      @reload="() => reload()"
    />

    <!-- Modal para PREVIEW -->
    <assignment-document-preview
        :entity-id="entityId"
        entity-type="activity"
        ref="previewModal"
        :can-delete="canManage"
        @reload="() => reload()"
    />

  </div>
</template>

<script>
import axios from "axios";
import entityNotifications from "·/components/entity-notifications";
import assignmentModal from "·/components/assignment-modal";
import taskModal from "·/components/task-modal";
import assignmentUpdateModal from "·/components/assignment-update-modal";
import taskUpdateModal from "·/components/task-update-modal";
// assignmentContributeModal de momento queda comentado, es reemplazado por assignmentContributeDrawingModal
// import assignmentContributeModal from "·/components/assignment-contribute-modal";
import assignmentCompletedModal from "·/components/assignment-completed-modal";
import assignmentDocumentPreview from "./assignment-document-preview";
import assignmentCertifiedExecutionModal from "./assignment-certified-execution-modal";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import { mapGetters } from "vuex";
import ChapterTableActivity from "../components/chapters/chapter-table-activity.vue";
import PictogramTableActivity from "../components/pictograms/pictogram-table-activity.vue";
import assignmentContributeDrawingModal from "../components/assignments/assignment-contribute-drawing-modal.vue";
import { format } from 'date-fns'

const ASSIGNMENT_TASK = 'task';
const ASSIGNMENT_RESOURCE = 'resource';
const ASSIGNMENT_CERTIFIER = 'certifier';
const ASSIGNMENT_CERTIFIER_HEAD = 'certifier-head';
const PICTOGRAM = 'pictogram';

export default {
  name: "activity-assignments",

  components: {
    entityNotifications,
    assignmentModal,
    taskModal,
    taskUpdateModal,
    assignmentUpdateModal,
    assignmentContributeDrawingModal,
    // assignmentContributeModal,
    assignmentCompletedModal,
    ScaleLoader,
    assignmentDocumentPreview,
    assignmentCertifiedExecutionModal,
    ChapterTableActivity,
    PictogramTableActivity
  },

  props: {
    activityId: { type: Number, required: true },
    activityName: { type: String, required: true },
    activityPlanningCode: { type: String, required: false, default: '' },
    pictogramId: { type: Number, required: false, default: null },
    subactivityId: { type: Number, default: 0 },
    canAdd: { type: Boolean, default: false },
    canManageCertifiers: { type: Boolean, default: false },
    canManage: { type: Boolean, default: false },
    endDate: { type: String },
    currentStartDate: { type: String },
    currentEndDate: { type: String },
    blockedAssignments: { type: Number, required: true },
  },

  data: () => ({

    selected: undefined,
    selectedHistory: undefined,
    selectedSubHistory: undefined,
    list: [],
    listTasks: [],
    listCertifiers: [],
    listChapters: [],
    listResources: [],
    pictogram: null,
    documents: [],
    hasWeightingError: false,
    weighting: 0,
    lines: [],
    entityId: null,
    entityType: "activity",
    isListOpen: false,
    isLoading: true,
    checkboxIsLoading: false,
    openedAssignmentId: 0,
    documentId: 0,
    realEndDate: null,

    assignmentsLocked: false,
    assignmentType: 'certifiers',

    mainActiveTab: 0,
    isLoadingTask: false,
    isLoadingResource: false,
    isLoadingCertifier: false,
    isLoadingCertifierHead: false,
    isLoadingPictogram: false,
    chaptersCount: 0,
  }),

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      user: "auth/user",
    }),
  },

  watch: {
    activityId(id) {
      if (id > 0) {
        this.isLoading = true;
        this.getList();
      }
    },

    mainActiveTab(value) {
      switch (value) {
        case 1:
          this.getList(ASSIGNMENT_TASK)
          break;

        case 2:
          this.getList(ASSIGNMENT_CERTIFIER)
          break;

        case 3:
          this.getList(ASSIGNMENT_CERTIFIER_HEAD)
          break;

        case 4:
          this.getPictogram()
          break;

        default: // 0
          this.getList(ASSIGNMENT_RESOURCE)
          break;
      }
    }
  },

  async mounted() {
    this.assignmentsLocked = !!this.blockedAssignments
    this.isLoadingResource = true
    this.isLoadingTask = true
    this.isLoadingCertifier = true
    this.isLoadingCertifierHead = true

    // solo mostramos y cargamos las mediciones de recursos, tareas, certificadoras, chapters solo si no tiene asignado un
    // pictograma
    if(this.pictogramId == null) {
      await this.getList(ASSIGNMENT_RESOURCE, true);
      await this.getList(ASSIGNMENT_TASK, true);
      await this.getList(ASSIGNMENT_CERTIFIER, true);
      await this.getList(ASSIGNMENT_CERTIFIER_HEAD, true);
    } else
      await this.getPictogram();

    let self = this;
    EventBus.$on("refreshAssignmentChangedNotifications", function (e) {
      if (self.entityId === e.activity_id) {
        self.isLoading = true;
        self.reload();
      }
    });
    EventBus.$on("refreshAssignmentCreatedNotifications", function (e) {
      if (self.entityId === e.activity_id) {
        self.isLoading = true;
        self.reload();
      }
    });
    EventBus.$on("refreshAssignmentDeletedNotifications", function (e) {
      if (self.entityId === e.activity_id) {
        self.isLoading = true;
        self.reload();
      }
    });
  },

  created() {
    this.entityId = this.subactivityId || this.activityId;

    // Date fix
    let date_aux = this.endDate.split("/");
    this.realEndDate = date_aux[2] + '-' +date_aux[1] +'-' +date_aux[0];
  },

  beforeDestroy(){
  	EventBus.$off("refreshAssignmentChangedNotifications");
  	EventBus.$off("refreshAssignmentCreatedNotifications");
  	EventBus.$off("refreshAssignmentDeletedNotifications");
  },

  methods: {

    getRealChildren(rows){

      return rows.filter(assignment => assignment.is_header_row == 0).length;

    },

    dateToText(date) {
      let dateObject = new Date(date);
      return format(dateObject, 'dd/MM/yyyy HH:mm')
    },

    showPreview(document) {
      this.document = document;
      this.$refs.previewModal.show(this.document);
      if (this.document.exif) {
        EventBus.$emit("load-locations", this.document.exif);
      }
    },

    displayDetails(rowId){

      this.selected = (this.selected == rowId) ? undefined : rowId;
    },

    displayHistory(rowId){

      this.selectedHistory = (this.selectedHistory == rowId) ? undefined : rowId;
    },


    displaySubHistory(rowId){

      this.selectedSubHistory = (this.selectedSubHistory == rowId) ? undefined : rowId;
    },

    // async getList() {where_type
    //   let args = {
    //     withLines: 1
    //   };

    //   let url = "/api/v2/assignments/activity/" + this.entityId;
    //   const response = await axios.get(url, { params: args });
    //   let data = response.data;
    //   if (data && data.assignments) {
    //     this.listTasks = data.assignments.filter(assignment => assignment.type == ASSIGNMENT_TASK);
    //     this.listResources = data.assignments.filter(assignment => ((assignment.type == ASSIGNMENT_RESOURCE) && (assignment.is_header_row == 0 )));
    //     this.listCertifiers = data.assignments.filter(assignment => assignment.type == ASSIGNMENT_CERTIFIER );
    //     this.listChapters = data.assignments.filter(assignment => assignment.type == ASSIGNMENT_CERTIFIER_HEAD);
    //     // listChapters tiene el listado de mediciones cabecera, y las que tengan certifiers colgando las tendrán en el atributo 'children'
    //     // Estos children serán añadidos a listChapters para ser dibujados por completo en el arbol. Esto proceso se hace
    //     // en el metodo _listChapters de chapter-table-activity.vue
    //     // Por eso tomamos el numero de capitulos aqui antes de que se inserten en listChapters el resto de mediciones 'certifiers'
    //     this.chaptersCount = this.listChapters.length
    //     this.documents = data.documents || [];
    //     this.hasWeightingError = this.checkWeightingError();
    //     this.isLoading = false;
    //   }
    // },

    async getList(type, force_load = false) {
      if (this.loadAssignments(type, force_load)) {
        let args = {
          withLines: 1,
          where_type: type
        };

        this.stateLoading(type, true) // habilitamos el loadig que corresponde
        let url = "/api/v2/assignments/activity/" + this.entityId;
        const response = await axios.get(url, { params: args });

        let data = response.data;
        if (data && data.assignments) {

          if( type === ASSIGNMENT_TASK )
            this.listTasks = data.assignments;

          if( type === ASSIGNMENT_RESOURCE )
            this.listResources = data.assignments;

          if( type === ASSIGNMENT_CERTIFIER )
            this.listCertifiers = data.assignments;

          if( type === ASSIGNMENT_CERTIFIER_HEAD )
            this.listChapters = data.assignments;

          // listChapters tiene el listado de mediciones cabecera, y las que tengan certifiers colgando las tendrán en el atributo 'children'
          // Estos children serán añadidos a listChapters para ser dibujados por completo en el arbol. Esto proceso se hace
          // en el metodo _listChapters de chapter-table-activity.vue
          // Por eso tomamos el numero de capitulos aqui antes de que se inserten en listChapters el resto de mediciones 'certifiers'
          this.chaptersCount = data.chaptersCount || this.listChapters.length
          this.documents = data.documents || [];
          this.hasWeightingError = this.checkWeightingError();
          this.stateLoading(type, false) // deshabilitamos el loading que corresponde
        }

      }
    },

    async getPictogram() {

      if (!this.isLoadingPictogram && this.pictogram == null && this.pictogramId != null) {
        this.isLoadingPictogram = true
        let url = "/api/v2/pictogram/" + this.pictogramId;
        const response = await axios.get(url, { params: {} });

        let data = response.data;
        if (data && data.pictogram) {
          this.pictogram = data.pictogram;
        }

      }
      this.isLoadingPictogram = false
    },

    async reload() {
      // TODO: lo que a continuación se hace no es optimo para el nuevo modo de obtener las mediciones
      // en la notificación debería llegar un parametro notificando qué tipo de medición se actualizó
      // ademas mandamos un parametro force_load en true para forzar la carga de todas las mediciones
      if (this.pictogramId == null) {
        this.getList(ASSIGNMENT_TASK, true);
        this.getList(ASSIGNMENT_RESOURCE, true);
        this.getList(ASSIGNMENT_CERTIFIER, true);
        this.getList(ASSIGNMENT_CERTIFIER_HEAD, true);
      } else {
        this.pictogram = null // seteamos a null para que se recargue el pictograma
        await this.getPictogram();
      }

      this.$emit("reload");
    },

    /**
     * Comprueba si puede abrirse el modal de edición para esa fila
     */
    canOpenEditModal(row) {
      return this.canManage; // || row.user_id === this.user.id. Solo editan managers proyecto
    },

    /**
     * Comprueba si puede añadir o editar mediciones
     */
    canEditAssignments() {
      return !this.assignmentsLocked || (this.assignmentsLocked && this.canManageCertifiers)
    },

    /**
     * Comprueba si la fila es de una tarea
     */
    isTask(row) {
      return row.type === "task";
    },

    /**
     * Obtiene el valor utilizado dentro de la pelotita (cantidad ejecutada)
     */
    getDisplayQuantity(row) {
      if (this.isTask(row)) return "✔";

      return this.twoDecimals(this.getExecutedQuantity(row));
    },

    getRemaining(row) {
      if (this.isTask(row)) return "";

      if (row.completed) return 0;

      return this.twoDecimals(row.partial - this.getExecutedQuantity(row));
    },

    /**
     * Convierte el tipo de la fila a un string traducible
     */
    getTypeClass(row) {
      let classes = row.completed ? "completed " : "";
      if (this.isTask(row)) classes += "task";
      else {
        classes += "resource text-grey-darker ";
        if (row.completed) classes += "text-green";
        else if (parseFloat(row.executed_quantity) > parseFloat(row.total_units)) {
          classes += "text-red";
        }
      }
      return classes;
    },

    /**
     * Comprueba si hay errores en la ponderación
     */
    checkWeightingError() {
      if (this.listResources.length === 0 || this.listTasks.length === 0) return false; //la lista está vacía, ignoremos el error por ahora.

      //sumamos todos los pesos para comprobar si dan 100
      this.weightingTasks = this.listTasks
        .reduce((total, item) => {
          return total + item.weight;
        }, 0)
        .toFixed(2);

      this.weightingResources = this.listResources
        .reduce((total, item) => {
          return total + item.weight;
        }, 0)
        .toFixed(2);

      return (parseFloat(this.weightingTasks) + parseFloat(this.weightingResources)) != 100;
    },

    /**
     * Calcula el ratio (float de 0 a 1) de completado
     */
    getCompletionRate(row) {
      // las tareas valen 1 o 0
      if (this.isTask(row)) return row.completed;

      if (row.completed) return 1;

      // Es una tarea binaria  si no tiene un 0 como ejecutado siquiera
      if (this.isTask(row)) return 0;

      let rate = this.getExecutedQuantity(row) / row.partial;

      //El ratio no puede ser mayor que 1
      return rate > 1 ? 1 : rate;
    },

    /**
     * Comprueba si la fila dada tiene lineas en el histórico
     */
    hasLines(row) {
      if (!row.lines) return false;
      //Si puede o no mostrar lineas
      return row.lines.length ? true : false;
    },

    /**
     * Comprueba si la fila dada tiene lineas en el histórico
     */
    hasObservation(row) {
      if (!row.observation) return false;
      //Si puede o no mostrar lineas
      return true;
    },

    getExecutedQuantity(row) {
      let executed = row.parent_assignment_id ? row.executed_quantity : row.executed_quantity_sub
      return executed || 0;
    },

    getWeight(row) {
      return row.parent_assignment_id ? row.weightBc3 : row.weightBc3Sub
    },

    /**
     * @deprecated
     */
    openDetails(row, index) {
      //NOTA: Esta función es vestigial y ahora mismo no se usa, las lineas vienen precargadas
      //TODO: dejar de usar this.lines, para actualizar el assignment directamente
      if (typeof this.lines[row.id] === "undefined") {
        this.isLoading = true;
        let self = this;
        let url = "/api/v2/assignment/" + row.id;
        axios.get(url).then((response) => {
          if (response.data && response.data.success) {
            self.lines[row.id] = response.data.assignment.lines;
          }
          self.isLoading = false;
          self.openedSAssignmentId = row.id;
        });
      } else {
        this.isLoading = false;
        this.openedAssignmentId = row.id;
      }
    },

    /**
     * Abre el modal para añadir tarea
     */
    addTaskButtonClicked() {
      this.$refs.task_modal.show();
    },

    /**
     * Abre el modal para añadir medición
     */
    addResourceButtonClicked() {
      this.$refs.assignment_modal.show();
    },


    /**
     * Abre el modal para añadir medición
     */
    addSubResource(assignment) {
      this.$refs.assignment_modal.show(assignment);
    },

    /**
     * Abre el modal para editar una medición
     */
    onClickEdit(assignment) {
      this.$refs.assignment_update_modal.show(assignment);
    },

        /**
     * Abre el modal para editar una medición
     */
    onClickTaskEdit(task) {
      this.$refs.task_update_modal.show(task);
    },

    onNameClick(assignment) {
      if (this.isTask(assignment)) return; //las tareas no tienen modal

      // this.$refs.assignment_contribute_modal.show(assignment.id); // comentamos, por que es reemplado por contribute drawing
      this.$refs.assignment_contribute_drawing_modal.show(assignment.id);
    },

    onNameNewClick(assignment) {
      if (this.isTask(assignment)) return; //las tareas no tienen modal

      this.$refs.assignment_contribute_drawing_modal.show(assignment.id);
    },

    annulAction(line, assignmentId) {

      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", ['la ejecución '+line.executed_quantity]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = `/api/v2/assignment/${assignmentId}/line/${line.id}`;
          const { data } = await axios.delete(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("removed_successfully"),
              type: "is-success",
              position: "is-top-right"
            });

            this.getList(ASSIGNMENT_RESOURCE, true); // recargar lista mediciones
            this.$emit("reload"); // recalcular progreso actividad

          } else {
            // Error
            this.$toast.open({
              message: data.msg || this.$t("remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
          this.isLoading = false;
        }
      });
    },

    /**
     * Llamamos al modal de completar
     */
    onCompleteChanged(assignment) {
      if (this.user.collaborator != 1) {
        this.$refs.assignment_completed_modal.show(assignment.id, assignment.completed);
      }
    },

    /**
     * Carga la lineas de una medición en concreto
     * @deprecated Debido a que estamos cargando las lineas junto a las mediciones
     */
    loadLines(assignment) {
      let url = "/api/v2/assignment/" + assignment.id;
      let args = {
        withLines: 1,
      };
      self = this;
      axios
        .get(url, { params: args })
        .then(function (response) {
          assignment.lines = response.data.assignment.lines;
        })
        .catch((error) => {
          self.$notify.error("update_error");
        });
    },

    /**
     * Convierte el numero a dos decimales obligatoriamente
     * @param number
     * @returns {string}
     */
    twoDecimals(number) {
      return parseFloat(number).toFixed(2);
    },

    calculateCertifierValues(item, type='pending'){

      if (item.lines) {
        if (type == 'pending')
          return ((item.total_quantity - item.executed_quantity) == 0)
          ? (item.total_quantity - item.executed_quantity).toFixed()
          : (item.total_quantity - item.executed_quantity).toFixed(2)

        return (item.executed_quantity == null || item.executed_quantity == 0) ? 0 : (item.executed_quantity * 1).toFixed(2)

      }

      return type == 'pending' ? item.total_units : 0;

    },

    // bloquea/desbloquea manipular las mediciones certificadoras
    async onClickUnlockMeasurementsButton() {
      const { data } = await axios.post('/api/v2/activity/' + this.activityId + '/blockedAssigments', { blocked: !this.assignmentsLocked })
      if (data && data.success) {
        this.assignmentsLocked = !this.assignmentsLocked;
        this.$notify.success(this.assignmentsLocked ? 'success_updating_blocked_assignments' : 'success_updating_unblocked_assignments')
      } else {
        this.$notify.error('error_updating_blocked_assignments')
      }
    },

    isImage(extension) {
      let valid_extensions = ['png', 'jpg', 'jpeg']
      return valid_extensions.includes(extension)
    },

    loadAssignments(type, force_load) {
      if( type === ASSIGNMENT_TASK )
        return force_load ? true : (!this.isLoadingTask && this.listTasks.length == 0);

      if( type === ASSIGNMENT_RESOURCE )
        return force_load ? true : (!this.isLoadingResource && this.listResources.length == 0);

      if( type === ASSIGNMENT_CERTIFIER )
        return force_load ? true : (!this.isLoadingCertifier && this.listCertifiers.length == 0);

      if( type === ASSIGNMENT_CERTIFIER_HEAD )
        return force_load ? true : (!this.isLoadingCertifierHead && this.listChapters.length == 0);
    },

    stateLoading(type, state) {
      if( type === ASSIGNMENT_TASK )
        this.isLoadingTask = state;

      if( type === ASSIGNMENT_RESOURCE )
        this.isLoadingResource = state;

      if( type === ASSIGNMENT_CERTIFIER )
        this.isLoadingCertifier = state;

      if( type === ASSIGNMENT_CERTIFIER_HEAD )
        this.isLoadingCertifierHead = state;
    },

    getNameOrCodePartner(partners) {
      if (partners.length == 0)
        return 'Orden pedido sin asignar'

      // de moemnto solo consideramos al primer partner
      return partners[0].code ? partners[0].code : partners[0].name
    }
  },
};
</script>
<style>
.table-assignments.b-table .table .chevron-cell {
  padding-right: 0;
  padding-left: 2px;
}

.table-assignments.b-table thead tr th {
  padding: 4px;
}

.table-assignments thead tr th .th-wrap {
  color: #606f7b;
}

.table-assignments tbody tr td.vertical-center {
  vertical-align: middle;
}

.table-assignments tbody:nth-child(even) {
  background-color: #dfebf5;
  border-radius: 3px;
}

.task.completed span {
  background-color: #7ba7cc !important;
}

td.type-column.resource span {
  color: inherit;
}
</style>

<style scoped>
.is-clickable:hover {
  cursor: pointer;
}

.table-assignments >>> * {
  border: none;
}

.table-assignments >>> th {
  background-color: transparent;
  border-bottom: 1px lightgray solid;
}

.table.is-narrow td,
.table.is-narrow th {
  padding: 0.25em 0.25em;
}

/* Solo las tareas usan la pelotita */
td.type-column.task >>> span {
  margin: auto;
  display: block;

  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
  font-weight: 600;
  font-size: 1.15rem;
  background-color: #b8c2cc;
  color: white;

  overflow: visible;
  border-radius: 9999px;
}

td.task.type-column {
  vertical-align: middle;
  padding: 0.25em;
  height: 2.5rem;
  width: 2.5rem;
}
</style>
