<template>
<div>
  <div class="panel">

    <!-- linea clasificacion -->
    <div class="flex w-full flex-col lg:flex-row items-start">
      <div class="flex flex-col lg:flex-row items-start w-full lg:w-full">
        <!-- Clase de incidencia -->
        <div class="w-full lg:w-1/3 lg:mr-1">
          <l-form-select
              :form="form"
              field="class_id"
              :options="issueClassesList"
              label="issue_classification"
              :disabled="!createNew && !form.is_open"
              icon="spell-check"
              full
              optionDisplayField="name"
            />
        </div>
      </div>
    </div>

    <!--  linea tipo, disciplina, origen -->
    <div class="flex w-full flex-col lg:flex-row items-start mt-3">
      <div class="flex flex-col lg:flex-row items-start w-full lg:w-full">
        <!-- Tipo de incidencia -->
        <div class="w-full lg:w-1/3 lg:mr-1">
          <l-form-select
              :form="form"
              field="type_id"
              :options="issueTypesList"
              label="issue_type"
              :disabled="!createNew && !form.is_open"
              icon="spell-check"
              full
              optionDisplayField="name"
            />
        </div>
        <!-- Disciplina de incidencia -->
        <div class="w-full lg:w-1/3 lg:mr-1">
          <l-form-select
              :form="form"
              field="discipline_id"
              :options="issueDisciplinesList"
              label="issue_disciplines"
              :disabled="!createNew && !form.is_open"
              icon="spell-check"
              full
              optionDisplayField="name"
            />
        </div>
        <!-- Origen de incidencia -->
        <div class="w-full lg:w-1/3 lg:mr-1">
          <l-form-select
              :form="form"
              field="origin_id"
              :options="issueOriginsList"
              label="issue_origins"
              :disabled="!createNew && !form.is_open"
              icon="spell-check"
              full
              optionDisplayField="name"
            />
        </div>
      </div>
    </div>

    <div class="flex w-full flex-col lg:flex-row items-start mt-3">
      <div class="flex flex-col lg:flex-row items-start w-full lg:w-full">
        <div class="w-full lg:w-1/3 lg:mr-1">
          <l-form-input ref="issueName"
            :form="form"
            field="name"
            label="issue_name"
            :disabled="!createNew && !form.is_open"
          />
        </div>
        <div class="w-full lg:w-1/3 lg:mr-1">
          <l-form-select
            :form="form"
            field="location_id"
            :options="locationsList"
            label="select_location"
            :disabled="!createNew && !form.is_open"
            icon="map-marker-alt"
            full
            optionDisplayField="name"
          />
        </div>
        <div class="w-full lg:w-1/3 lg:mr-1">
          <span class="label">{{ $t('estimated_resolution_date') }}</span>
          <b-datepicker
            v-model="form.estimated_resolution_date"
            :placeholder="$t('estimated_resolution_date')"
            :month-names="$t('months_names')"
            :day-names="$t('days_names')"
            :first-day-of-week="parseInt($t('first_day_of_week'))"
            :disabled="!createNew && !form.is_open"
            icon-pack="fas"
          >
          </b-datepicker>
        </div>
      </div>
    </div>

    <Transition name="slide">
    <div v-show="showMap" class="flex w-full flex-col lg:flex-row items-start mt-3">
      <!-- Mapa -->
      <div class="panel">
        <input type="hidden" name="address_id" id="address_id" value="">
        <googlemaps ref="mapRef"
          name="issue-location"
          :datamaps="userLocation"
          :draggable="true"
          @updPlace="updPlace"
          :newPlace="''"
          inputAutocomplete="address_id" />
      </div>
    </div>
    </Transition>

    <!-- Abierta / Cerrada -->
    <div class="w-full lg:w-1/5 flex items-center mt-3">
      <b-field v-if="! createNew" :label="$t('issue_opened')">
        <b-switch v-model="form.is_open"
          type="is-success"
          :disabled="createNew"
        >
          {{ $t(form.is_open ? 'yes' : 'no') }}
        </b-switch>
      </b-field>
    </div>

    <!-- elegir plano donde señalar la incidencia -->
    <div class="flex mt-8">
        <b-field v-if="createNew" :label="'Señalar en el plano'">
          <b-switch v-model="setOnDrawing"
            type="is-success"
          >
            {{ $t(setOnDrawing ? 'yes' : 'no') }}
          </b-switch>
        </b-field>
    </div>

    <Transition name="slide">
    <div class="flex flex-col lg:flex-row items-start w-full" v-if="setOnDrawing" key="drawing_list">

        Selecciona un plano donde marcar la incidencia:<br>
        <div v-for="(drawing) in drawings" :key="drawing.id" v-on:click="loadDrawing(drawing)" class="text-blue text-sm rounded-xl inline-block mb-2 py-0.5 px-2 border-2 border-blue bg-blue-200 mr-1 cursor-pointer">
          {{ drawing.name }}
        </div>
        <div v-if="drawings.length==0" class="text-red">No existen planos asociados a la actividad</div>
    </div>
    </Transition>

    <Transition name="slide">
    <div class="flex flex-col lg:flex-row items-start w-full" v-show="setOnDrawing && drawing!=null" key="drawing_canvas">

        <div class="w-full pr-2">
          Haz click en la parte del plano donde desea señalar la incidencia:

          <div style="overflow:auto;">
            <div id="zoom">
              <a v-on:click="loadDrawing(drawingDocument, parseFloat(scale)-0.2)" class="ml-1 button is-light is-small">
                <b-icon pack="fa" class="float-left" size="is-small" icon="magnifying-glass-minus" ></b-icon>
              </a>
              <a v-on:click="loadDrawing(drawingDocument, parseFloat(scale)+0.2)" class="ml-1 button is-light is-small">
                <b-icon pack="fa" class="float-left" size="is-small" icon="magnifying-glass-plus"></b-icon>
              </a>
              <a v-on:click="resetMarkers" class="ml-1 button is-light is-small" v-if="markerIsSet">
                Reset &nbsp;&nbsp;<b-icon pack="fa" class="float-left" size="is-small" icon="undo-alt"></b-icon>
              </a>
            </div>

            <canvas id="canvas" width="595" height="720" @click="setMarker" style="width: auto;border: 1px solid gray; box-shadow: 2px 2px 4px gray;"></canvas>
          </div>
        </div>

    </div>
    </Transition>

    <!-- Elegir pictograma  -->

    <div class="flex mt-8">
        <b-field v-if="createNew" :label="'Señalar en el pictograma'">
          <b-switch v-model="setOnPictogram"
            type="is-success"
          >
            {{ $t(setOnPictogram ? 'yes' : 'no') }}
          </b-switch>
        </b-field>
    </div>

    <Transition name="slide">
      <div v-if="setOnPictogram">
          <KonvaViewerPictogram ref="refViewerPictogram"
            :canActionsPictogram="canActionsPictogram"
            :pictogram="pictogram"
            :work-layer="workLayer"
            :type-register="typeRegister"
          > </KonvaViewerPictogram>
      </div>
    </Transition>



    <!-- Descripción -->
    <div class="flex flex-col lg:flex-row w-full mt-8">
      <div class="w-full lg:w-4/5 lg:pr-2">
        <span class="label">{{ $t('issue_description') }}</span>
        <quill-editor v-model="form.description" :options="editorOption"></quill-editor>
      </div>
      <!-- Fechas -->
      <div class="w-full lg:w-1/5">
        <l-form-input
          :form="form"
          field="start_date"
          label="issue_date"
          :disabled="true"
        />
        <l-form-input v-if="! form.is_open"
          :form="form"
          field="end_date"
          label="closed_date"
          :disabled="true"
        />
      </div>
    </div>
    <div class="flex flex-col lg:flex-row w-full mt-8">
      <div class="w-full lg:w-4/5 lg:pr-2">
        <span class="label">{{ $t('issue_steps_to_implement') }}</span>
        <quill-editor v-model="form.steps_to_implement" :options="editorOption"></quill-editor>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row w-full mt-8">
      <div class="w-full lg:w-4/5 lg:pr-2">
        <span class="label">{{ $t('issue_steps_implemented') }}</span>
        <quill-editor v-model="form.steps_implemented" :options="editorOption"></quill-editor>
      </div>
    </div>
    <div class="flex w-full flex-col lg:flex-row mt-4">
      <!-- ETIQUETAS -->
      <div class="w-full lg:w-4/5 items-center lg:pr-2">
        <span class="label">{{ $t('issue_tags') }}</span>
        <model-tags
                :model-id="parseInt(issueId)"
                model-type="issue"
                :allow-edit="false"
                :add-from-list="true"
                :parent-model-id="this.project.id"
                parent-model-type="project"
                ref="model_tags"
        />
      </div>

    </div>

    <div class="flex items-start w-full flex-col sm:flex-row mt-8">
      <!-- Responsable -->
      <userlist ref="manager"
        title="issue_manager"
        icon="bullhorn"
        :api-url="apiUrl"
        :users-permission-type="[]"
        :disabled="false"
        :only-one-user="true"
        :can-replace="true"
        :is-issue="true"
        :activity-id="parseInt(activityId)"
        class="w-full sm:w-1/2 sm:mr-3"
        roleName="activity_admin" />

      <!-- Creador -->
      <userlist ref="creator"
        title="issue_creator"
        icon="user-secret"
        :api-url="apiUrl"
        :users-permission-type="[]"
        :disabled="true"
        class="w-full sm:w-1/2 sm:ml-3" />
    </div>

    <!-- Participantes / Colaboradores -->
    <div class="flex w-full flex-col sm:flex-row items-start mt-8">
      <userlist ref="participants"
        title="users_participants"
        icon="users"
        :api-url="apiUrl"
        :parent-entity-id="createNew ? null : parseInt(issueId)"
        :users-permission-type="['participate']"
        :allow-create-users="false"
        :local-mode="createNew"
        :disabled="!createNew && !form.is_open"
        class="w-full sm:w-1/2 sm:mr-3"
        roleName="participant" />
      <userlist ref="collaborators"
        title="users_collaborators"
        icon="address-book"
        :api-url="apiUrl"
        :parent-entity-id="createNew ? null : parseInt(issueId)"
        :users-permission-type="['collaborate']"
        :allow-external-users="true"
        :allow-create-users="false"
        :local-mode="createNew"
        :only-external-users="true"
        :project-id="this.project.id"
        :disabled="!createNew && !form.is_open"
        class="w-full sm:w-1/2 sm:ml-3"
        roleName="collaborator" />
    </div>
  </div>
  <form @submit.prevent="sendIssueData" @keydown="form.onKeydown($event)"
    class="w-full flex items-center justify-end mb-8">
    <!-- <v-button v-if="!(createNew && !form.is_open)" antigua condicion confusa y no necesaria -->
    <v-button v-if="!processingData"
      type="submit" icon="thumbs-up" icon-pack="far" class="mr-4"
    >
      {{ $t('save') }}
    </v-button>

    <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 mr-4 btn-blue leading-6 text-sm shadow rounded-md text-white hover:bg-orange-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
      {{ $t('save') }}
    </button>
    <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button>
  </form>
</div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import Form from 'vform'
import Userlist from '~/components/UserBox/UserList'
import EventBus from '~/plugins/bus'
import ModelTags from "../../components/model-tags";
import PdfCanvas from "~/plugins/pdfCanvas";
import { format } from 'date-fns'
import Googlemaps from '~/components/GoogleMaps.vue'
import KonvaViewerPictogram from '../../components/pictograms/viewer-pixi/ViewerPageKonva.vue'

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-emoji/dist/quill-emoji.css";
import {quillEditor} from "vue-quill-editor";
import Quill from "quill";
import emoji from "quill-emoji/dist/quill-emoji";

Quill.register("modules/quill-emoji", emoji);
import Mention from "quill-mention";

import VueTribute from "vue-tribute";

export default {
  middleware: 'auth',
  title: 'issue_edit_page_title',
  subtitle: 'issue_edit_page_title',
  menuOption: '2-6',

  components: {
    quillEditor,
    VueTribute,
    Userlist,
    ModelTags,
    Googlemaps,
    KonvaViewerPictogram,
  },

  watch: {

    // al marcar 'señalar en plano' poner el emplazamiento por defecto del proyecto si se encuentra
    setOnDrawing: function() {
      if (this.setOnDrawing) {
        let loc = this.locationsList.filter( location => location.reference_code.toLowerCase() == 'project_location' )
        if (loc.length) {
          this.form.location_id = loc[0].id
        }
      }
    },

    'form.location_id': function(newVal, oldVal) {

      if (newVal == 0) {
        this.showMap = true

        if (navigator.geolocation) {

          // navegador pide permiso ubicacion
          navigator.geolocation.getCurrentPosition((position) => {

            // para el post new location
            this.newLocation.latitude = position.coords.latitude
            this.newLocation.longitude = position.coords.longitude
            // para recargar el mapa con el marker en esta position
            this.userLocation.push({
              id: 1,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              draggable: true, // inicialmente se muestra ubicacion usuario pero se permite mover
            });
            EventBus.$emit("load-locations", this.userLocation);

          });
        }

      } else {
        this.showMap = false
      }
    },

  },

  data: () => ({
    createNew: false,
    activityId: null,
    subactivityId: null,
    issueId: null,
    processingData: false,
    form: new Form({
      name: '',
      class_id: null,
      type_id: '',
      discipline_id: null,
      origin_id: null,
      location_id: '',
      is_open: true,
      start_date: null,
      end_date: null,
      description: '',
      manager_user: null,
      activity_id: null,
      document_id: null, // si elige marcar incidencia en plano, id documento y coordenadas click
      // document_page: 0,  Obsoleto, de cuando solo habia un marker. Estos 3 irán dentro del array markers
      // document_x: 0,
      // document_y: 0,
      markers: [], // array de markers en plano
      estimated_resolution_date: null,
      steps_to_implement: null,
      steps_implemented: null,
    }),
    locationsList: [],
    issueClassesList: [],
    issueTypesList: [],
    issueDisciplinesList: [],
    issueOriginsList: [],
    setOnDrawing: false, // indica si la incidencia será marcada sobre el plano
    setOnPictogram: false, // indica si la incidencia será marcada sobre el pictograma
    markerIsSet: false, // flag que indica si ya se señaló una ubicación en el plano
    drawings: [], // documentos (planos) vinculados a actividad padre, donde ubicar la incidencia
    drawing: null, // posible futuro plano seleccionado donde clicar para crear incidencia (objeto pdfjsLib)
    drawingDocument: null, // posible futuro plano seleccionado (objeto document normal)
    scale: 1, // scale of selected drawing (zoom)
    showMap: false, // para indicar emplazamiento personalizado distinto de los emplazamientos de proyecto
    userLocation: [], // ubicacion del usuario para centrar el mapa
    apiUrl: '/api/v2/issue',
    newLocation: new Form({
      reference_code: '',
      name: '',
      latitude: '',
      longitude: '',
      is_virtual: 1,
    }),
    editorOption: {
      placeholder: "",
      modules: {
        toolbar: {
          container: [
            ["emoji"],
            ["bold", "italic", "underline", "strike"],
            [{list: "ordered"}, {list: "bullet"}],
            ["link", "clean"]
          ],
          handlers: {
            emoji: function () {
            }
          }
        },
        "emoji-toolbar": true,
        "emoji-textarea": false,
        "emoji-shortname": true,
        mention: {
          offsetTop: 2,
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@", "#"],
          source: function (searchTerm, renderList, mentionChar) {
            let values;
            let i = 0;
            let self = this;
            if (mentionChar === "@") {
              values = at.state.atUsers;
            }

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              if (values) {
                for (i = 0; i < values.length; i++) {
                  if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                }
              }
              renderList(matches, searchTerm);
            }
          }
        }
      },
      formats: [
        'mention',
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula'
        // 'image'
        // 'video'
      ]
    },


    // para trabajar los pictogramas
    pictogram: null,
    workLayer: null, // // capa donde  trabajará el usuario, solo permite trabajar en esa capa si null trabaja en todas
    activityId: null,
    subactivityId: null,
    typeRegister: 'issue',
    canActionsPictogram: {
      'save': false,
      'select_entity': true,
      'showButtonInfo': false,
      'showButtonReturn': false,
      'showButtonCompletion': false
    }
  }),

  computed: {
    ...mapGetters({
      user: 'auth/userBasicInfo',
      project: 'app/project',
    }),
  },

  created () {
    // Parámetros en la ruta
    this.activityId = this.$route.params.activity_id
    this.subactivityId = this.$route.params.subactivity_id
    this.issueId = this.$route.params.issue_id
    // Crear nueva o editar
    this.createNew = (this.$route.name === 'issue.create') || (this.$route.name === 'subissue.create')
    if (this.createNew) {
      let activityId = this.subactivityId ? this.subactivityId : this.activityId

      // El responsable es el mismo de la actividad
      this.getActivityManagerUser(activityId)
      this.getActivityTags(activityId) //por defecto tags de la actividad
      this.getActivitySelectableDocuments(activityId) // listado de planos vinculados con la actividad
      this.form.activity_id = activityId
      this.form.start_date = this.getNow()
      this.$options.title = 'issue_create_page_title'
    } else {
      this.$options.title = 'issue_edit_page_title'
    }
    this.$options.subtitle = ['subactivity_create_page_subtitle', [this.$route.params.activityName]]
  },

  mounted () {
    // Lista con las localizaciones del proyecto
    this.getLocationsList()
    this.getIssueTypesList()
    this.getDataPictogram()  // method get data pictogram
    if (this.createNew) {
      // El creador de la incidencia
      this.$refs.creator.usersList.unshift(this.user)
    } else {
      // Editar
      this.getIssueData()
      // Refrescamos las listas de usuarios
      this.$refs.manager.refreshData()
      this.$refs.creator.refreshData()
      this.$refs.participants.refreshData()
      this.$refs.collaborators.refreshData()
    }
    this.$refs.issueName.setFocus()
  },

  methods: {
    async getLocationsList() {
      const { data } = await axios.get('/api/v2/locations', {params: { 'p': this.project.id, 'all': true }})
      if (data && data.success) {
        this.locationsList = data.locations
        this.locationsList.unshift({ id: 0, name: 'Señalar en el mapa', reference_code: 'custom place' })
      } else {
        this.$notify.error('error_loading_locations')
      }
    },

    async getIssueTypesList() {
      const { data } = await axios.get('/api/v2/project/' + this.project.id + '/issue-classes-types-disciplines-origins')
      if (data && data.success) {
        this.issueClassesList = data.classes
        this.issueTypesList = data.types
        this.issueDisciplinesList = data.disciplines
        this.issueOriginsList = data.origins
      } else {
        this.$notify.error('Error cargando tipos / disciplinas / origenes')
      }
    },

    async getActivityManagerUser(activityId) {
      let url = '/api/v2/activity/' + activityId + '/users'
      const { data } = await axios.get(url, {params: {p: 'admin', r: 'activity_admin'}})
      if (data && data.success) {
        // Añado el responsable de la actividad como responsable de la incidencia
        this.$refs.manager.usersList = data.users_list

        // Solo tras cargar manager, cargamos resto participantes (evita errores por asincronia)
        this.getProjectIssueUsers(this.project.id)

      } else {
        this.$notify.error('error_getting_manager_user')
      }
    },

    // Carga participantes definidos como usuarios de incidencia en el proyecto
    async getProjectIssueUsers(projectId) {
      let url = '/api/v2/project/' + projectId + '/users'
      const { data } = await axios.get(url, {params: {r: 'issue_user'}})
      if (data && data.success) {

        let managerId = this.$refs.manager.usersList[0].user_id
        let creatorId = this.$refs.creator.usersList[0].user_id

        // Añadimos a los usuarios de incidencia del proyecto como participantes (si no es manager ni creador)
        this.$refs.participants.usersList = data.users_list.filter(
          (user) => (user.user_id != managerId && user.user_id != creatorId)
        )

      } else {
        console.log("No issue users in project " + projectId) // solo advertimos por consola. Sin ser visible al user
        // this.$notify.error('error_getting_manager_user') Silencio el error. Si proyecto no tiene 'issue users', devuelve success:false, y parece error sin serlo
      }

      // Finalmente añadir al creador como participante (si no era ya manager)
      this.copyCreatorToParticipant()
    },

    async getActivityTags(activityId) {
      const { data } = await axios.get('/api/v2/activity/'+activityId+'/tags')
      if (data && data.success) {
        this.$refs.model_tags.initTags(data.tags)
      }
    },

    async getActivitySelectableDocuments(activityId) {
      const { data } = await axios.get('/api/v2/activity/' + activityId + '/selectable-documents')
      if (data && data.success) {
        // de los documentos vinculados, solo los de tipo 'planos'
        this.drawings = data.documents.filter((doc) => doc.project_document_is_drawing == 1)
      } else {
        this.$notify.error('error_loading_documents')
      }
    },

    // añade el creador como participante (en caso de no ser manager)
    copyCreatorToParticipant() {
      if (this.$refs.creator.usersList[0].user_id != this.$refs.manager.usersList[0].user_id) {
        this.$refs.participants.usersList.unshift(this.user)
      }
    },

    async getIssueData() {
      const { data } = await axios.get('/api/v2/issue/' + this.issueId)
      if (data) {

        this.form.name = data.data.name
        this.form.description = data.data.description
        this.form.location_id = data.data.location_id
        this.form.class_id = data.data.class_id
        this.form.type_id = data.data.type_id
        this.form.discipline_id = data.data.discipline_id
        this.form.origin_id = data.data.origin_id
        this.form.is_open = data.data.is_open == 1
        this.form.start_date = this.formatDate(data.data.created_at)
        this.form.end_date = data.data.closed_at == null ? this.getNow() : this.formatDate(data.data.closed_at)
        this.form.estimated_resolution_date = data.data.estimated_resolution_date ? new Date(data.data.estimated_resolution_date) : null
        this.form.steps_to_implement = data.data.steps_to_implement
        this.form.steps_implemented = data.data.steps_implemented
        this.getCreatorUser(data.data.creator_id)
        this.getManagerUser()
        this.$refs.model_tags.initTags(data.data.tags)
      } else {
        this.$notify.error('error_loading_issue_data')
      }
    },

    async getCreatorUser(creatorId) {
      const { data } = await axios.get('/api/v2/user/' + creatorId + '?basic=1')
      if (data && data.success) {
        this.$refs.creator.usersList.unshift(data.user)
      }
    },

    async getManagerUser() {
      let url = '/api/v2/issue/' + this.issueId + '/users'
      const { data } = await axios.get(url, {params: {p: 'admin', r: 'activity_admin'}})
      if (data && data.success) {
        this.$refs.manager.usersList = data.users_list
      }
    },

    async sendIssueData() {

      this.processingData = true

      // Añadimos responsable, participantes y colaboradores
      this.form.manager_user = this.$refs.manager.usersList.length > 0 ? this.$refs.manager.usersList[0].user_id : null
      this.form.participants = this.$refs.participants.getUsersIds()
      this.form.collaborators = this.$refs.collaborators.getUsersIds()
      if (this.form.estimated_resolution_date instanceof Date) {
        // this.form.estimated_resolution_date = this.form.estimated_resolution_date?.toISOString().substring(0,10) // timezone issues (-1 day)
        this.form.estimated_resolution_date = format(this.form.estimated_resolution_date, 'yyyy-MM-dd')
      }

      // Edit
      var url = this.apiUrl
      if (! this.createNew) {
        url += '/' + this.issueId
      }

      // Al crear nuevo y seleccionar ubicacion en mapa, crear primero la ubicacion virtual
      if (this.createNew && this.form.location_id == 0) {
        //crear location virtual y entonces con el id de la location virtual crear la incidencia
        let location = await this.addLocation()

        if (location) {
          this.form.location_id = location.id
        } else {
          this.$notify.error('location_change_fail')
          this.processingData = false
          return
        }
      }

      // Si elige señalar en plano y no seleccionó fichero
      if (this.setOnDrawing && this.form.document_id == null) {
        this.$notify.error('select_drawing_and_set_issue')
        this.processingData = false
        return
      }
      // se asegura de resetear document_id si setOnDrawing esta desmarcado (para no vincular issue con document en backend)
      if (! this.setOnDrawing) {
        this.form.document_id = null
      }

      if (this.setOnPictogram) {
        let entitiesWithIssue = this.$refs.refViewerPictogram.GetModifiedEntities()

        if (entitiesWithIssue.length === 0) {
          this.$notify.error('select_pictogram_and_set_issue')
          this.processingData = false
          return
        }
        else {
          this.form.pictogram_id = this.pictogram.id
          let arrType = entitiesWithIssue.map( (item) => item.type )
          this.form.pictogram_entity_type = arrType.filter((item, index) => arrType.indexOf(item) === index).join(';')
          this.form.pictogram_entities_with_issue = JSON.stringify(entitiesWithIssue)
        }
      }

      // Enviamos el form
      const { data } = await this.form.post(url)
      this.processingData = false
      if (data && data.success) {
        this.$refs.model_tags.syncTagsToModel(this.createNew ? data.issue_id : this.issueId)
        //console.log('actualizo tags de '+this.issueId,this.$refs.model_tags.tags)
        this.$notify.success(this.createNew ? 'success_creating_issue' : 'success_editing_issue')
        this.$router.go(-1)
      } else {
        this.$notify.error(this.createNew ? 'error_creating_issue' : 'error_editing_issue')
      }
    },

    getNow() {
      return this.formatDate(new Date())
    },

    formatDate(date) {
      let options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      let _date = new Date(date)
      return _date.toLocaleDateString('es-ES', options)
    },

    // carga la primera pagina del pdf en un etiqueta html canvas donde permita clicar la ubicacion de la incidencia
    async loadDrawing(drawing, scale=1) {

      this.form.document_id = drawing.document_id // para enviar a backend
      this.drawingDocument = drawing // guardamos para saber cual se seleccionó
      this.markerIsSet = false // documento recien cargado en canvas, no hay marker seleccionado

      PdfCanvas.setAssetUrl(this.asset(''))

      // nivel de zoom. Nunca menos de 0.4 ni mas de 2.6
      if (scale < 0.4) {
        scale = 0.4
      }
      if (scale > 2.6) {
        scale = 2.6
      }
      this.scale = scale.toFixed(2)

      this.drawing = await PdfCanvas.loadDocument(drawing, {scale: this.scale})

    },

    // dibuja un marker en la parte clickada sobre el plano
    async setMarker(event) {

      // if (this.markerIsSet) { Obsoleto. Es de cuando solo habia un marker

      //   // Si ya habia un marker, resetea el dibujo para establecer el marker en el nuevo sitio clicado
      //   this.drawing = await PdfCanvas.loadDocument(this.drawingDocument)
      //   this.markerIsSet = false

      // }

      if (this.drawing) {

        this.markerIsSet = true

        this.drawing.getPage(1).then(page => {

          let rect = event.target.getBoundingClientRect();
          let x = event.clientX - rect.left; // x position within the element.
          let y = event.clientY - rect.top;  // y position within the element.

          let canvas = document.getElementById('canvas')
          let context = canvas.getContext('2d')

          const img = new Image();
          img.onload = () => {
            context.drawImage(img, x-PdfCanvas.markerImg.width, y-PdfCanvas.markerImg.height);
          };
          img.src = this.asset(PdfCanvas.markerImg.src);

          // para enviar a backend y guardar en metadata. Como valor de x,y depende de nivel de zoom, guardaremos porcentaje
          this.form.markers.push({
            document_page: 0,
            document_x: parseFloat( (x * 100) / canvas.width ).toFixed(4),
            document_y: parseFloat( (y * 100) / canvas.height ).toFixed(4)
          })

        })
      }


    },


    // Reset all markers
    async resetMarkers() {
      this.markerIsSet = false
      this.form.markers = []
      // resetea el dibujo
      this.drawing = await PdfCanvas.loadDocument(this.drawingDocument)
    },


    // Para nuevas incidencias, en caso de seleccionar emplazamiento "en el mapa"
    // Crear previamente el emplazamiento con el flag "virtual" y luego crear la incidencia
    async addLocation () {

      let url = '/api/v2/location/' + this.project.id + '/addLocation'

      // referencia: issue + 3 chars/digits aleatorios + 3 digitos aleatorios
      this.newLocation.reference_code = 'issue_'
        + Math.random().toString(36).substring(2, 5) + '_' + Math.floor(100 + Math.random() * 900);

      const { data } = await this.newLocation.post(url)

      if (data.success) {

        return data.data

      } else {

        return null

      }
    },


    async updPlace(place) {
      this.newLocation.latitude = place.latitude
      this.newLocation.longitude = place.longitude
    },

    async getDataPictogram() {
      // Submit the form.
      let data = null

      await axios.get('/api/v2/activity/' + (this.subactivityId ? this.subactivityId : this.activityId) + '/pictogram')
        .then(response => (data = response.data))
        .catch(error => console.log(error));

      //const { data } = await this.form.post('api/v2/login')

      if (data && data.success) {
        this.pictogram = data.pictogram
      }
    }
  },

};
</script>

<style scoped>
/* FIX TEMPORAL. UN PAQUETE (PROBABLEMENTE @BIMDATA/VIEWER) ESTA SOBREESCRIBIENDO LA CLASE .flex-col */
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}
</style>
